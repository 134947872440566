export default {
  //规则1
  guize1: `
    1、活动对象：2G/3G/4G/5G移动运营商用户。<br />
    2、活动内容：活动期间凡首次在本页面成功开通业务（业务名称：移动联合会员，业务资费：10元~30元/月，话费扣款），即可获得30GB定向流量包，若续订，每月可领。<br />
    <span>30GB定向流量包（15GB快手+15GB头条抖音）</span><br />
    <span>15GB快手定向流量：</span>快手定向流量免流范围包括快手APP。流量包到账后生效，当月有效，定向流量范围不包括客户端内上传视频与图片、标题/评论/消息/私信等文本信息加载、直播中礼物素材加载、第三方网页浏览产生的流量。<br />
    此流量包不支持港澳台地区以及国际漫游;不可抵扣已产生的流量、不可与副卡共享，不可转赠至其他用户。<br />
    <span>15GB抖音头条定向流量：</span>头条抖音定向流量免流范围包括: 今日头条、抖音、火山小视频、西瓜视频、懂车帝、悟空问答温馨提醒:流量包到账后生效，当月有效，定向流量范围不包括上述APP中第三方广告、弹幕、直播类视频上传在线观看/下载/缓存第三方视频所产生的流量。<br />
    此流量包不支持港澳台地区以及国际漫游;不可抵扣已产生的流量、不可与副卡共享，不可转赠至其他用户。<br />
    （1）流量包兑换方式：点击进入<a class="cls_a_fuli"
    href="https://m.woread.diamond.aizhishifm.cn/benefit.html">【福利兑换中心】</a> -【流量权益】-选择【移动快手定向流量】【移动头条抖音定向流量】进行分别兑换，兑换成功后可前往定向APP使用；<br />
    兑换成功后，支付宝红包将直接发放至您申领的支付宝账户，英语趣配音月卡将直充至您申领的手机号账户内。<br />
    （2）温馨提示：使用相关权益时，请遵守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。<br />
    3、产品退订：<br />
    （1）开通包月会员后，如未退订，会在每个自然月初自动续费会员。<br />
    （2）自动续费可随时取消，如需取消，请您在下一次自然月扣费时间前，发送0000至10086，按照短信提示进行退订； <br />
    4、注意事项：<br />
    （1）每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
    （2）本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放（福利礼品包括会员权益、流量等）。<br />
    （3）已开通本业务的老用户不可再次参与本次推广活动。<br />
    （4）成功开通本业务，获取的权益福利不超过12个月，权益领取周期为自然月，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br />
    5、如有疑问可致电客服热线：4008888202。<br />
    `,
  guize2: `
    1、活动对象：2G/3G/4G/5G手机用户。<br />
    2、活动内容：活动期间凡首次在本页面成功开通联合会员业务（业务资费：19.9元/月），即可获得热门会员月卡权益21选1；<br />
    热门会员（月卡）21选1：芒果TV、腾讯视频、爱奇艺、优酷、哔哩哔哩、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、小红书VIP会员、京东plus会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS稻壳会员、饿了么、趣配音。<br />
    3、权益发放<br />
    （1）会员权益兑换方式：点击进入<a class="cls_a_fuli"
    href="https://m.woread.diamond.aizhishifm.cn/benefit.html">福利兑换中心</a>，选择喜爱的月会员进行兑换，登录对应平台即可使用；<br />
    4、注意事项：<br />
    （1）每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
    （2）如需退订本产品，请登录“支付宝-我的-设置-支付设置-免密支付/自动扣款”操作；本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放。<br />
    （3）已开通本业务的老用户不可再次参与本次推广活动。<br />
    （4）权益领取周期为自然月，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br />
    5、权益购买页面，老年及未成年人谨慎消费。<br />
    6、如有疑问可致电客服热线：4008888202。<br />
    `,
  guize3: `
    一、产品介绍 <br />
    1、甘肃移动电费联合会员：资费为19.9元／月，每月可享20元电费优惠券、5GB全国通用流量（自动下发）、5GB定向流量（用户领取）等会员权益。<br />
    二、权益使用规则<br />
    1、5GB国通用流量：会员产品订购成功后5GB全国通用流量自动充值到账，自到账后当月有效，过期失效，不结转，不共享。<br />
    2、5GB定向流量：适用于爱奇艺、优酷、腾讯视频、芒果TV、哔哩哔哩、咪咕视频APP;<br />
    5GB定向流量需用户自主领取，领取后当月有效，月底失效；定向流量使用优先级高于套餐内流量，不可结转、不可共享、不可转赠。<br />
    注： 您可登录甘肃移动微信公众号，在"优惠|福利﹣我的权益﹣我的"中领取定向流量，也可点击<br />
    https://dx.10086.cn/0YBjE7Nj领取权益。<br />
    3、20元电费优惠券<br />
    (1）首月订购成功后电费优惠券24小时内到账，次月起如不退订每月6日自动到账，直充到国网账户中，请下载网上国网APP并使用订购手机号进行登录和使用；电费优惠券仅限甘肃地区电表使用。<br />
    (2）登录网上国网APP，点击"我的"-"我的资产"-"优惠券"即可查看并使用电费优惠券。<br />
    (3）产品有效期：电费优惠券自到账之日起有效期为30天（含当日），请及时使用。<br />
        三、订购／取消规则 <br />
    1、产品订购后立即生效，有效期为12个月，到期后自动取消。<br />
    2、如提前解约需缴纳违约金，取消或终止请联系10086或前往营业厅办理，办理时需按未履约月进行赔付。<br />
    3、合约活动期内限制停机保号、分合过户、销户、携号转网，活动有赔付，终止需要去营业厅办理，办理时需按未履约月进行赔付。<br />
    四、客服电话<br />
    如您遇到会员权益不到账或者权益使用相关问题，您可拨打客服电话：400-010-0017。<br />
    `,
  //全民家微信包月
  guize4: `
    1、开通包月前请仔细阅读购买说明，本产品为全民家商城会员微信产品，连续包月资费19.9元/月，单次月卡25元/月，由微信直接扣费，本月不退订则视为同意自动续费，下月由微信发起自动续费服务。<br />
    2、开通本产品微信连续包月的用户，产品内所含特权1、2、3的福利和权益可同时享有，使用期限为自购买之日起30天内有效，请及时使用，过期失效。<br />
    3、本产品特权介绍：<br />
    3.1、特权1为互联网热门月会员权益N选1，含腾讯视频、爱奇艺、优酷视频、芒果TV、QQ音乐等多款热门权益，成功开通或续订本产品的用户每月可任意选择一款月会员权益进行使用；<br />
    3.2、特权2为全民家商城代金券，每月可享总价40元 （5元*2张、10元*1张、20元*1张），可在全民家商城中抵扣使用，每单可用1张；<br />
    3.3、特权3为全民家商城折扣，商城内商品全场8.8折，成功开通全民家会员微信连续包月的用户可自动获得该项福利。<br />
    4、权益领取及退订说明：<br />
    4.1、特权1权益领取路径为：点击<a class="cls_a_fuli"
    href="https://m.woread.diamond.aizhishifm.cn/benefit.html">福利兑换中心</a>，选择喜爱的月会员权益进行兑换使用，使用有效期为30天；<br />
    特权2、3请点击<a class="cls_a_fuli"
    href="https://www.bytxxz.com/qmj/vip.html">小程序短链</a>前往兑换。<br />
    4.2、如需退订本产品，请登录“微信-我-服务-钱包-支付设置-自动续费”，点击“扣费服务”，下滑找到本产品扣费页面，点击“底部关闭扣费服务”，即可成功退订本产品，退订后所有权益如在30天使用期内仍然有效，30天后过期失效。<br />
    5、如有任何问题，请致电客服电话：4008888202。<br />
    6、活动会根据实际运营情况适时调整，具体请以此页面实际显示为准。<br />
    【订购须知】<br />
    本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费,敬请知悉!<br />
    为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗<br />
    `,
  guize4B:`
  1、活动对象:2G/3G/4G/5G手机用户。<br />
  2、活动内容:活动期间凡首次在本页面成功开通联合会员业务(业务资费:19.9元/月)，即可获得热门会员月卡权益20选1，到期前24小时内将自动扣费续订例如用户在2024年3月15日
  10:56分订购了该会员，那么在2024年3月15日 10:56分~.2023年4月13日 10:55期间，用户可任选1款会员权益，且若用户在2024年4月12日10:55前未退订服务，那么在2024年4月12日
  10:55~2024年4月13日
  10:55期间将自动扣费下一个30天计费周期的费用，热门会员(月卡)20选1:芒果TV、腾讯视频、爱奇艺、优、哩哔哩、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、春克网盘超级会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS超级会员、饿了么、趣配音。<br />
  3、生效/失效方式:订购立即生效，当前计费周期费用(30天)一次性扣除，下一个计费周期自动续订，退订需在到期前24小时。<br />
  4、权益发放：会员权益兑换方式：点击进入 <a class="cls_a_fuli" href="https://migutg.chinaso.com/benefit.html">【福利兑换中心】</a>，选择喜爱的会员进行兑换，登录对应平台即可使用。<br />
  5、注意事项:<br />
  (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
  (2)如需退订本产品，请登录"微信-我-服务-支付设置-自动续费”,点击”扣费服务”，下滑找到本产品扣费页面，点击底部”关闭自动扣费服务”，即可成功退订本产品，退订后所有权益在当月使用期内仍有效，过期失效。<br />
  (3)已开通本业务的老用户不可再次参与本次推广活动。<br />
  (4)权益领取周期为自然月，热门权益需用户手动领取，如当月热门权益未领取次月无法补领上月权益，请及时领取。<br />
  6、如有任何问题，请致电客服电话：4008888202。<br />
  【订购须知】<br />
  本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费,敬请知悉!<br />
  为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗<br />`
  ,
  guize2E:`
  1、活动对象:2G/3G/4G/5G手机用户。<br />
  2、活动内容:活动期间凡首次在本页面成功开通联合会员业务(业务资费:19.9元/月)，即可获得热门会员月卡权益20选1，到期前24小时内将自动扣费续订例如用户在2024年3月15日 10:56分订购了该会员，那么在2024年3月15日 10:56分~.2023年4月13日 10:55期间，用户可任选1款会员权益，且若用户在2024年4月12日10:55前未退订服务，那么在2024年4月12日 10:55~2024年4月13日 10:55期间将自动扣费下一个30天计费周期的费用，热门会员(月卡)20选1:芒果TV、腾讯视频、爱奇艺、优、哩哔哩、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、春克网盘超级会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS超级会员、饿了么、趣配音。<br />
  3、生效/失效方式:订购立即生效，当前计费周期费用(30天)一次性扣除，下一个计费周期自动续订，退订需在到期前24小时。<br />
  4、权益发放：会员权益兑换方式：点击进入 <a class="cls_a_fuli" href="https://migutg.chinaso.com/benefit.html">【福利兑换中心】</a>，选择喜爱的会员进行兑换，登录对应平台即可使用。<br />
  5、注意事项:<br />
  (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
  (2)如需退订本产品，请登录"微信-我-服务-支付设置-自动续费”,点击”扣费服务”，下滑找到本产品扣费页面，点击底部”关闭自动扣费服务”，即可成功退订本产品，退订后所有权益在当月使用期内仍有效，过期失效。<br />
  (3)已开通本业务的老用户不可再次参与本次推广活动。<br />
  (4)权益领取周期为自然月，热门权益需用户手动领取，如当月热门权益未领取次月无法补领上月权益，请及时领取。<br />
  6、如有任何问题，请致电客服电话：4008888202。<br />
  【订购须知】<br />
  本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费,敬请知悉!<br />
  为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗<br />`
  ,
  //全民加微信连续包月和单次订购
  guize5: `
    1、开通包月前请仔细阅读活动规则及《全民家会员协议》，本产品为全民家会员(微信支付)产品，由微信直接扣费。<br />
    2、资费：<br />
    2.1、连续包月：19.9元/月（本月不退订则视为同意自动续费，下月由微信发起自动续费服务），连续包月扣费规则请仔细阅读页面中的《全民家连续包月服务协议》。<br />
    2.2、单次月卡：25元<br />
    3、产品介绍：<br />
    3.1、全民家小程序旨在打造乡村电商传播平台，针对乡村特色产品开展助农直销，解决乡村产品销路问题；打造特色民宿预定功能，例如森林人家、星空露营等，促活乡村旅游，帮助乡镇机构、村民增加效益，拉动乡村经济。利用互联网的强大流量，助力乡村产业振兴。<br />
    3.2、全民家会员可享权益：<br />
    3.2.1省钱：月月享优惠券（单笔订单需满5.01元才可使用），每月可享总价20元 （5元*4张），可在全民家商城中抵扣使用，每单可用1张；会员更享专属折扣，全场商品8.8折；享受差价补偿。<br />
    3.2.2实惠：付费会员可享受双倍积分累计（普通用户积分兑换比例为1：1），购物可抵扣，多买多优惠。<br />
    3.2.3服务：1V1专属客服，全程链路通畅，咨询退换无忧。<br />
    3.2.4互联：额外享互联网热门权益联名特权多选1，适用各类人群。<br />
    互联网热门月会员权益N选1，含腾讯视频、爱奇艺、优酷视频、芒果TV、QQ音乐等多款热门权益，成功开通或续订本产品的用户每月可任意选择一款月会员权益进行使用；<br />
    4、权益领取及退订说明：<br />
    4.1、会员中心路径为：前往 <a href="#" id="clickableElement" v-on:click="urlxcx">全民家小程序</a> ，点击我-会员卡片进入，即可使用全民家会员与商城。 <br />
    4.2、连续包月用户如需退订本产品：<br />
    4.2.1请登录“微信-我-服务-钱包-支付设置-自动续费”，点击“扣费服务”，下滑找到本产品扣费页面，点击“底部关闭扣费服务”，即可成功退订本产品，退订后所有权益如在30天使用期内仍然有效，30天后过期失效。<br />
    4.2.2 请前往微信<a href="#" id="clickableElementb" v-on:click="urlxcx">全民家小程序</a>-我-取消续订，即可取消，下月开始不再收费。<br />
    5、如有任何问题，请致电客服电话：4008888202。<br />
    6、活动会根据实际运营情况适时调整，具体请以此页面实际显示为准。<br />
    `,
  guize6: `
    1、活动对象：2G/3G/4G/5G移动手机用户。<br />
    2、活动内容：活动期间凡在本页面成功开通联合会员业务并使用该业务12个月内不取消（业务资费：10-30元/月，具体业务名称和资费以短信为主），即可获得2大权益；<br />
    权益1：100元话费券红包：<br />
    用户成功开通联合会员,并承诺使用联合会员12个月内不取消则可获得2张5元话费充值抵扣券和10张9元话费充值抵扣券;若用户中途退订本业务将不予领取后续每月的话费红包。<br />
    权益2：热门会员（月卡）20选1：<br />
    芒果TV、腾讯视频、爱奇艺、优酷、哔哩哔哩、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、夸克网盘超级会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS稻壳会员、饿了么、趣配音。<br />
    3、权益发放：<br />
    （1）权益1兑换方式：联合会员用户需关注“趣充优兑”公众号,在公众号登录后可进行充值和抵扣；第1-2个月,充值20元话费可抵扣5元;第3-12个月充值20元可抵扣9元;每月可使用1张抵扣券。<br />
    （2）权益2兑换方式：联合会员用户需关注“趣充优兑”公众号,选择喜爱的月会员进行兑换，登录对应平台即可使用；<br />
    4、注意事项：<br />
    （1）充值仅限开通联合会员业务的号码本机充值。<br />
    （2）每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
    （3）如需退订本产品，拨打10086客服电话退订或者发送短信0000至10086进行退订<br />
    （4）已开通本业务的老用户不可再次参与本次推广活动。 <br />
    （5）权益领取周期为自然月，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br />
    5、如有疑问可致电客服热线：4008888202。<br />
    `,
  guize6B: `
    1、活动对象：2G/3G/4G/5G移动手机用户。<br />
    2、活动内容：活动期间凡在本页面成功开通联合会员业务并使用该业务12个月内不取消（业务资费：29元/月，具体业务名称和资费以短信为主），即可获得2大权益；<br />
    权益1：100元话费券红包：<br />
    用户成功开通联合会员,并承诺使用联合会员12个月内不取消则可获得2张5元话费充值抵扣券和10张9元话费充值抵扣券;若用户中途退订本业务将不予领取后续每月的话费红包。<br />
    权益2：热门会员（月卡）20选1：<br />
    芒果TV、腾讯视频、爱奇艺、优酷、哔哩哔哩、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、夸克网盘超级会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS稻壳会员、饿了么、趣配音。<br />
    3、权益发放：<br />
    （1）权益1兑换方式：联合会员用户需关注“趣充优兑”公众号,在公众号登录后可进行充值和抵扣；第1-2个月,充值20元话费可抵扣5元;第3-12个月充值20元可抵扣9元;每月可使用1张抵扣券。<br />
    （2）权益2兑换方式：联合会员用户需关注“趣充优兑”公众号,选择喜爱的月会员进行兑换，登录对应平台即可使用；<br />
    4、注意事项：<br />
    （1）充值仅限开通联合会员业务的号码本机充值。<br />
    （2）每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
    （3）如需退订本产品，拨打10086客服电话退订或者发送短信0000至10086进行退订<br />
    （4）已开通本业务的老用户不可再次参与本次推广活动。 <br />
    （5）权益领取周期为自然月，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br />
    5、如有疑问可致电客服热线：4008888202。<br />
    `,
  guize6C: `
    1、活动对象：2G/3G/4G/5G移动手机用户。<br />
    2、活动内容：活动期间凡在本页面成功开通咪咕阅读畅享会员业务并使用该业务12个月内不取消（业务资费：9.9元/月，具体业务名称和资费以短信为主），即可获得2大权益；<br />
    权益1：100元话费券红包：<br />
    用户成功开通咪咕阅读畅享会员,并承诺使用咪咕阅读畅享会员12个月内不取消则可获得2张5元话费充值抵扣券和10张9元话费充值抵扣券;若用户中途退订本业务将不予领取后续每月的话费红包。<br />
    权益2：热门会员（月卡）20选1：<br />
    芒果TV、腾讯视频、爱奇艺、优酷、哔哩哔哩、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、夸克网盘超级会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS稻壳会员、饿了么、趣配音。<br />
    3、权益发放：<br />
    （1）权益1兑换方式：咪咕阅读畅享会员用户需关注“趣充优兑”公众号,在公众号登录后可进行充值和抵扣；第1-2个月,充值20元话费可抵扣5元;第3-12个月充值20元可抵扣9元;每月可使用1张抵扣券。<br />
    （2）权益2兑换方式：咪咕阅读畅享会员用户需关注“趣充优兑”公众号,选择喜爱的月会员进行兑换，登录对应平台即可使用；<br />
    4、注意事项：<br />
    （1）充值仅限开通咪咕阅读畅享会员业务的号码本机充值。<br />
    （2）每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
    （3）如需退订本产品，拨打10086客服电话退订或者发送短信0000至10086进行退订<br />
    （4）已开通本业务的老用户不可再次参与本次推广活动。 <br />
    （5）权益领取周期为自然月，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br />
    5、如有疑问可致电客服热线：4008888202。<br />
    `,
  guize6D: `
    1、活动对象：2G/3G/4G/5G移动手机用户。<br />
    2、活动内容：活动内容：活动期间凡在本页面成功开通联合会员业务并使用该业务12个月内不取消（业务资费：10-30元/月，具体业务名称和资费以短信为主）;<br />
    用户成功开通联合会员,并承诺使用联合会员12个月内不取消，则每月可获得爱奇艺VIP会员；若用户中途退订本业务将不予领取后续每月的爱奇艺会员。<br />
    3、权益发放：联合会员用户需关注“趣充优兑”公众号,选择爱奇艺月会员进行兑换，兑换成功后登录爱奇艺平台即可使用；<br />
    4、注意事项：<br />
    （1）充值仅限开通联合会员业务的号码本机充值。<br />
    （2）每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
    （3）如需退订本产品，拨打10086客服电话退订或者发送短信0000至10086进行退订<br />
    （4）已开通本业务的老用户不可再次参与本次推广活动。 <br />
    （5）权益领取周期为自然月，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br />
    5、如有疑问可致电客服热线：4008888202。<br />
    `,
  guize6E: `
    1、活动对象：2G/3G/4G/5G移动手机用户。<br />
    2、活动内容：活动内容：活动期间凡在本页面成功开通联合会员业务并使用该业务12个月内不取消（业务资费：10-30元/月，具体业务名称和资费以短信为主）;<br />
    用户成功开通联合会员,并承诺使用联合会员12个月内不取消，则每月可获得芒果VIP会员；若用户中途退订本业务将不予领取后续每月的芒果会员。<br />
    3、权益发放：联合会员用户需关注“趣充优兑”公众号,选择芒果会员进行兑换，兑换成功后登录芒果平台即可使用；<br />
    4、注意事项：<br />
    （1）充值仅限开通联合会员业务的号码本机充值。<br />
    （2）每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
    （3）如需退订本产品，拨打10086客服电话退订或者发送短信0000至10086进行退订<br />
    （4）已开通本业务的老用户不可再次参与本次推广活动。 <br />
    （5）权益领取周期为自然月，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br />
    5、如有疑问可致电客服热线：4008888202。<br />
    `,
  guize6F: `
  1、活动对象：2G/3G/4G/5G移动运营商用户。<br />
  2、活动内容：活动期间凡首次在本页面成功开通业务(业务名称:联合会员，业务资费:9.9-10元/月)，即可随机获得咪咕阅读VIP或咪咕短剧精品包权益，100元话费券红包(2张5元话费充值抵扣券和10张9元话费充值抵扣券)和热门会员月卡20选1。热门会员(21选1)包含:爱奇艺、腾讯视频、优酷、芒果TV、bilibili、酷狗音乐豪华绿钻、网易云音乐、酷我音乐、KEEP、新浪微博、WPS超级会员、知乎、夸克网盘超级会员、懒人听书会员、喜马拉雅会员、QQ超级会员、百度网盘超级会员、英语趣配音会员、饿了么会员。<br />
  3、礼品发放：<br />
    (1)咪咕阅读会员权益领取方式:前往应用商城下载咪咕阅读APP登录即可使用。(会员享有的具体权益内容以咪咕阅读"会员中心”页面中关于会员特权具体描述为准。<br />
    (2)咪咕短剧精品包领取方式:下载咪咕阅读APP登录后在短剧模块找到短剧精品包专区即可使用。<br />
    (3)热门会员月卡兑换方式:点击进入<a
    href="https://migutg.chinaso.com/benefit.html">【福利兑换中心】</a>选择一款喜爱的会员权益，兑换成功后可前往相应的APP使用；100元话费抵扣券领取方式：关注"趣充优兑"公众号，使用订购手机号在公众号登录后可进行充值和抵扣；第1-2个月,充值20元话费可抵扣5元;第3-12个月充值20元可抵扣9元每月可使用1张抵扣券。温罄提示：使用相关权益时，请道守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权偿等。<br />
  4、产品退订：<br />
  开通包月会员后，会在会员到期前自动续费会员，自动续费可随时取消,如需取消，请您在下一次扣费前时间前在咪咕阅读APP中“我的-我的订购-包月管理自动续费"退订:或致电4008888202客服热线协助退订。<br />
  5、注意事项：<br />
  （1）每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
  （2）本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放(福利礼品包括会员权益、话费等)。<br />
  （3）已开通本业务的老用户不可再次参与本次推广活动。<br />
  （4）成功开通本业务，获取的权益福利不超过12个月，权益领取周期为30天，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。 <br />
  6、如有疑问可致电客服热线：4008888202。<br />
  `,
  guize7: `
    1、活动对象：2G/3G/4G/5G移动运营商用户。<br>
    2、活动内容：活动期间凡首次在本页面成功开通业务（业务名称：咪咕悦读畅享会员，业务资费：9.9元/月），即可获得咪咕阅读VIP权益+爆款会员月卡20选1。
    爆款会员（20选1)：爱奇艺黄金会员（月卡）、腾讯视频会员（月卡）、优酷视频会员（月卡）、芒果TV会员（月卡）、bilibili会员（月卡）、酷狗音乐会员（月卡）、QQ音乐豪华绿钻（月卡）、网易云音乐会员（月卡）、酷我音乐会员（月卡）、KEEP（月卡）、新浪微博会员（月卡）、WPS稻壳会员（月卡）、知乎盐选会员（月卡）、夸克网盘超级会员（月卡）、懒人听书会员（月卡）、喜马拉雅会员（月卡）、QQ超级会员（月卡）、百度网盘超级会员（月卡）、英语趣配音会员（月卡）、饿了么会员（月卡）。<br>
    3、礼品发放：<br>
    （1）咪咕阅读会员权益领取方式：前往应用商城下载咪咕阅读APP登录即可使用。（会员享有的具体权益内容以咪咕阅读“会员中心”页面中关于会员特权具体描述为准。）<br>
    （2）爆款月卡会员权益兑换方式：点击进入<a
      href="https://migutg.chinaso.com/benefitGs.html">福利兑换中心</a> -选择您需要的月卡会员权益进行兑换，兑换成功后可前往权益所在平台激活使用；<br>
    温馨提示：使用相关权益时，请遵守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。<br>
    4、产品退订：开通包月会员后，会在会员到期前自动续费会员。自动续费可随时取消，如需取消，请您在下一次扣费前时间前在咪咕阅读APP中“我的-我的订购-包月-管理自动续费”退订；或致电4008888202客服热线协助退订。<br>
    5、注意事项：<br>
    （1）每个手机号仅限参与一次，如当月不退订，次月自动续订。<br>
    （2）本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放（福利礼品包括会员权益、流量等）。<br>
    （3）已开通本业务的老用户不可再次参与本次推广活动。<br>
    （4）成功开通本业务，获取的权益福利不超过12个月，权益领取周期为30天，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br>
    6、如有疑问可致电客服热线：4008888202。<br>
    `,
  guize7B: `
  1、活动对象：2G/3G/4G/5G移动运营商用户。<br>
  2、活动内容：活动期间凡首次在本页面成功开通业务(业务名称:联合会员，业务资费:9.9-10元/月)，即可随机获得咪咕阅读VIP或咪咕短剧精品包权益，热门会员月卡＋美团外卖代金券21选1。热门会员(21选1)包含：爱奇艺、腾讯视频、优酷、芒果TV、bilibili、酷狗音乐豪华绿钻、网易云音乐、酷我音乐、KEEP、新浪微博、WPS超级会员、知乎、夸克网盘超级会员、懒人听书会员、喜马拉雅会员、QQ超级会员)、百度网盘超级会员、英语趣配音会员、饿了么会员、美团外卖10元代金券。<br>
  3、礼品发放：<br>
  （1）咪咕阅读会员权益领取方式:前往应用商城下载咪咕阅读APP登录即可使用。(会员享有的具体权益内容以咪咕阅读“会员中心”页面中关于会员特权具体描述为准。)<br>
  （2）咪咕短剧精品包领取方式：下载咪咕阅读APP登录后在短剧模块找到短剧精品包专区即可使用。<br>
   (3)热门会员月卡兑换方式：点击进入<a
   href="https://migutg.chinaso.com/benefitGs.html">【福利兑换中心】</a>选择一款喜爱的会员权益，或选择代金券权益-美团外卖-10元代金券进行兑换，兑换成功后可前往相应的APP使用；温罄提示:使用相关权益时，请道守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。<br>
  4、产品退订:开通包月会员后，会在会员到期前自动续费会员，自动续费可随时取消，如需取消，请您在下一次扣费前时间前在咪咕阅读APP中“我的-我的订购-包月管理自动续费"退订;或致电4008888202客服热线协助退订。<br>
  5、注意事项：<br>
  （1）每个手机号仅限参与一次，如当月不退订，次月自动续订。<br>
  （2）本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放（福利礼品包括会员权益、流量等）。<br>
  （3）已开通本业务的老用户不可再次参与本次推广活动。<br>
  （4）成功开通本业务，获取的权益福利不超过12个月，权益领取周期为30天，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br>
  6、如有疑问可致电客服热线：4008888202。<br>
  `,
  guize8: `
    【订购须知】<br />
    1、开通包月前请仔细阅读活动规则及《全民家会员协议》，本产品为全民家会员(微信支付)产品，由微信直接扣费。<br>
    2、资费<br>
    2.1、连续包月: 19.9元/月 (本月不退订则视为同意自动续费，下月由微信发起自动续费服务)，连续包月扣费规则请仔细阅读页面中的《全民家连续包月服务协议》。<br>
    2.2、单次月卡: 25元<br>
    3、产品介绍:<br>
    3.1、全民家小程序旨在打造乡村电商传播平台，针对乡村特色产品开展助农直销，解决乡村产品销路问题;打造特色民宿预定功能，例如森林人家、星空露营等，促活乡村旅游，帮助乡镇机构、村民增加效益，拉动乡村经济利用互联网的强大流量，助力乡村产业振兴3.2、全民家会员可享权益:<br>
    3.2.1省钱: 月月享优惠券 (单笔订单需满5.01元才可使用) ，每月可享总价20元 (5元*4张)，可在全民家商城中抵扣使用，每单可用1张，会员更享专属折扣，全场商品8.8折:享受差价补偿。<br>
    3.2.2实惠: 付费会员可享受双倍积分累计(普通用户积分兑换比例为1: 1) ，购物可抵扣，多买多优惠。<br>
    3.2.3服务: 1V1专属客服，全程链路通畅咨询退换无忧。<br>
    4、权益领取及退订说明:<br>
    4.1、会员中心路径为: 前往 全民家小程序点击我-会员卡片进入，即可使用全民家会员与商城。<br>
    4.2、连续包月用户如需退订本产品4.2.1请登录“微信-我-服务-钱包-支付设置自动续费”，点击“扣费服务”，下滑找到本产品扣费页面，点击“底部关闭扣费服务即可成功退订本产品，退订后所有权益如在30天使用期内仍然有效，30天后过期失效。<br>
    4.2.2 请前往微信全民家小程序-我-取消续订，即可取消，下月开始不再收费。<br>
    5、如有任何问题，请致电客服电话4008888202。<br>
    6、活动会根据实际运营情况适时调整，具体请以此页面实际显示为准。<br>
    `,
  guize9: `
    1、活动对象：2G/3G/4G/5G手机用户。<br />
    2、活动内容：活动期间凡首次在本页面成功开通联合会员业务（业务资费：19.9元/月），即可获得热门会员月卡权益20选1，到期前24小时内将自动扣费续订<br />
    例如用户在2023年3月15日 10:56分订购了该会员，那么在2023年3月15日 10:56分～2023年4月13日 10:55期间，用户可任选1款会员权益。且若用户在2023年4月12日 10:55前未退订服务，那么在2023年4月12日 10:55～2023年4月13日 10:55期间将自动扣费下一个30天计费周期的费用。<br />
    热门会员（月卡）20选1：芒果TV、腾讯视频、爱奇艺、优酷、哔哩哔哩、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、夸克网盘超级会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS稻壳会员、饿了么、趣配音。<br />
    3、生失效方式：订购立即生效，当前计费周期费用（30天）一次性扣除，下一个计费周期自动续订，退订需在到期前24小时。<br />
    4、权益发放
    （1）会员权益兑换方式：点击进入<a class="cls_a_fuli"
      href="https://m.woread.diamond.aizhishifm.cn/benefit.html">福利兑换中心</a>，选择喜爱的月会员进行兑换，登录对应平台即可使用；<br />
    5、注意事项：<br />
    （1）每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
    （2）如需退订本产品，请登录“支付宝-我的-设置-支付设置-免密支付/自动扣款”操作；本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放。<br />
    （3）已开通本业务的老用户不可再次参与本次推广活动。<br />
    （4）权益领取周期为自然月，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br />
    6、如有疑问可致电客服热线：4008888202。<br />
    【订购须知】<br />
    1、本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉!<br />
    2、为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗。<br />
    `,
  guize10: `
    1、活动对象:2G/3G/4G/5G移动运营商用户<br />
    2、活动内容:活动期间凡首次在本页面成功开通业务(业务名称:咪咕悦读黄金会员，业务资费:29元/月)，即可获得咪咕阅读VP权益+20元话费红包。<br />
    3、礼品发放<br />
    (1)咪咕阅读会员权益领取方式:前往应用商城下载咪咕阅读APP登录即可使用。内容以咪咕阅读“会员中心”(会员享有的具体权益页面中关于会员特权具体描述为准。)<br />
    (2)20元话费权益兑换方式:<br />
    点击进入福利兑换中心选择订购手机号对应的运营商话费红包，领取成功后，话费充值将于3个工作日内到账。<br />
    4、产品退订:
    开通包月会员后，会在会员到期前自动续费会员。自动续费可随时取消,如需取消,请您在下一次扣费时间前在咪咕阅读APP中“我的-我的订购-包月-管理自动续费”退订;或致电4008888202客服热线协助退订。<br />
    5、注意事项:<br />
    (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
    (2)本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放(福利礼品包括会员、话费)。<br />
    (3)已开通本业务的老用户不可再次参与本次推广活动。<br />
    (4)成功开通本业务，获取的权益福利不超过12个月，权益领取周期为30天，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br />
    6、如有疑问可致电客服热线:4008888202。<br />
    `,
  // 咪咕悦读黄金会员29
  guize11: `
    1、活动对象:2G/3G/4G/5G移动运营商用户<br />
    2、活动内容:活动期间凡首次在本页面成功开通业务(业务名称:咪咕悦读黄金会员，业务资费:29元/月)，即可获得咪咕阅读VP权益+20元话费红包。<br />
    3、礼品发放<br />
    （1）咪咕阅读会员权益领取方式：前往应用商城下载咪咕阅读APP登录即可使用。（会员享有的具体权益内容以咪咕阅读“会员中心”页面中关于会员特权具体描述为准。）<br />
     (2)20元话费权益兑换：点击进入<a class="cls_a_fuli"
     href=" https://migutg.chinaso.com/benefitGs.html?bllProductCode=WUSI_CMCC_YDGV2900&packageCode=PC20HF">福利兑换中心</a>，输入订购手机号领取兑换，收到话费充值到账短信及领取成功
    4、产品退订:
    开通包月会员后，会在会员到期前自动续费会员。自动续费可随时取消,如需取消,请您在下一次扣费时间前在咪咕阅读APP中“我的-我的订购-包月-管理自动续费”退订;或致电4008888202客服热线协助退订。<br />
    5、注意事项:<br />
    (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
    (2)本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放(福利礼品包括会员、话费)。<br />
    (3)已开通本业务的老用户不可再次参与本次推广活动。<br />
    (4)成功开通本业务，获取的权益福利不超过12个月，权益领取周期为30天，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br />
    6、如有疑问可致电客服热线:4008888202。<br />
    `,
  // 咪咕悦读黄金会员29
  guize12: `
      1、产品名称：咪咕悦读黄金会员。<br />
      2、产品资费：话费支付29元/月。<br />
      <span class="colorTitle">3、产品对象：中国移动用户。</span><br />
      <span class="colorTitle">4、生效时间：订购后立即生效，不取消按月自动续订、续费；可随时取消，取消立即生效，当前周期内可继续享受权益，次月起不再续订。</span><br />
      5、产品内容：活动期间凡首次在本页面成功开通业务(业务名称:咪咕悦读黄金会员，业务资费:29元/月)，即可获得咪咕悦读黄金会员VIP权益，并赠送20元话费红包(若次月续订业务，权益仍会发放，不续订则不发放)。<br />
      6、权益领取：<br />
      (1)咪咕悦读黄金会员权益领取方式：前往应用商城下载咪咕阅读APP登录即可使用。(会员享有的具体权益内客以咪咕阅读“会员中心”页面中关于会员特权具体描述为准。)<br />
      (2)20元话费权益兑换方式：<br />
      方式一：点击进入福利兑换中心，选择话费权益-移动话费-20元话费”，输入订购手机号领取；兑换成功后，话费充值将于1个工作日内到账。<br />
      方式二：业务订购成功后，在下发的权益短信中点击相关链接进入<a class="cls_a_fuli"
      href="https://migutg.chinaso.com/benefitGs.html?bllProductCode=MIGU_CMCC_2900&packageCode=PC20HF">福利兑换中心</a>，选择相应的话费券权益输入订购手机号领取；兑换成功后，话费充值将于1个工作日内到账。<br />
      7、办理须知：订购业务前请仔细阅读活动规则，订购本业务，即视为同意接受本业务及其相关活动的所有条款或规则。<br />
      8、退订方式：咪咕阅读APP-[我的]-[我的包月]-[自动续费管理]退订；或致电客服热线4008888202协助退订。<br />
      9、热门会员权益需用户月底前领取，用户需至指定领取入口领取权益，按自然月每月可领取一次权益。会员有效期30天，从领取当天开始计算。如当月权益未领取，次月无法补领。<br />
      10、客服咨询：更多详情请拨打客服热线4008888202。客服工作时间：周一到周日8:00-21:00。<br />

    `,
  // 咪咕悦读黄金会员29
  guize13: `
     1、活动对象:2G/3G/4G/5G移动运营商用户<br />
     2、活动内容:活动期间凡首次在本页面成功开通业务(业务名称:咪咕悦读黄金会员，业务资费:29元/月)，即可获得咪咕阅读VP权益+20元话费红包。<br />
     3、礼品发放<br />
     （1）咪咕阅读会员权益领取方式：前往应用商城下载咪咕阅读APP登录即可使用。（会员享有的具体权益内容以咪咕阅读“会员中心”页面中关于会员特权具体描述为准。）<br />
      (2)7天5GB流量权益兑换方式：点击进入<a class="cls_a_fuli"
      href="https://migutg.chinaso.com/benefitGs.html?bllProductCode=WUSI_CMCC_YDGV2900&packageCode=PC201CMCCL5G">【福利兑换中心】</a>，选择订购手机号对应的运营商流量即可领取使用。<br />
     （3）热门会员月卡权益兑换方式：点击进入<a class="cls_a_fuli"
     href="https://migutg.chinaso.com/benefitGs.html?bllProductCode=WUSI_CMCC_YDGV2900&packageCode=PC201CMCCL5G">【福利兑换中心】</a>，选择您需要的月卡会员权益进行兑换，兑换成功后可前往权益所在平台激活使用。
     4、产品退订:
     开通包月会员后，会在会员到期前自动续费会员。自动续费可随时取消,如需取消,请您在下一次扣费时间前在咪咕阅读APP中“我的-我的订购-包月-管理自动续费”退订;或致电4008888202客服热线协助退订。<br />
     5、注意事项:<br />
     (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
     (2)本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放(福利礼品包括会员、话费)。<br />
     (3)已开通本业务的老用户不可再次参与本次推广活动。<br />
     (4)成功开通本业务，获取的权益福利不超过12个月，权益领取周期为30天，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br />
      6、如有疑问可致电客服热线:4008888202。<br />
  `,
  // 24.9
  guize14: `
  1、活动对象:2G/3G/4G/5G移动运营商用户<br />
  2、活动内容：活动期间凡首次在本页面成功开通影音联合会员业务(业务资费:24.9元/月)，即可获得喜马拉雅会员月卡＋热门会员月卡权益20选1，到期前24小时内将自动扣费续订。例如用户在2023年3月15日 10:56分订购了该会员，那么在2023年3月15日 10:56分~2023年4月13日 10:55期间，用户可享喜马拉雅会员权益和任选1款会员权益。且若用户在2023年4月12日 10:55前未退订该服务，那么在2023年4月12日 10:55~2023年4月13日 10:55期间将自动扣费下一个30天计费周期的费用，热门会员(月卡)20选1：芒果TV、腾讯视频、爱奇艺、优酷、哔哩、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、夸克网盘超级会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS稻壳会员、饿了么、趣配音。<br />
  3、生效方式：订购立即生效，当前计费周期费用(30天)一次性扣除，下一个计费周期自动续订，退订需在到期前24小时。<br />
  4、权益发放：
  (1)会员权益兑换方式：订购成功页点击进入或直接点击<a class="cls_a_fuli"
  href="https://m.woread.diamond.aizhishifm.cn/redemptionCenter.html">【福利兑换中心】</a>到权益领取页面，选择喜马拉雅会员和任意一款喜爱的月卡会员进行兑换，登录对应平台即可使用。<br />
  5、注意事项:<br />
  (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。<br />
  (2)如需退订本产品，请登录“支付宝-我的-设置-支付设置-免密支付/自动扣款"操作；本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放。<br />
  (3)已开通本业务的老用户不可再次参与本次推广活动。<br />
  (4)权益领取周期为自然月，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。<br />
  6、如有疑问可致电客服热线:4008888202。<br />
  7、订购须知<br />
  (1)本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉!<br />
  (2)为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗。<br />
  `,
  //  沃悦读-5G-畅听好声音20元包规则
  guize15: `
  <span class="colorTitle">1、活动时间:2024年1月10日-2024年12月31日</span></br>
  <span class="colorTitle">2、活动对象:</span>中国联通2G/3G/4G/5G套餐手机用户</br>
  <span class="colorTitle">3、活动规则:</span>活动期间凡首次成功开通中国联通“沃悦读-5G-畅听好声音20元包”业务 (每月20元) ，即可获得以下权益：</br>
    权益一: 如用户未退订“沃悦读-5G-畅听好声音20元包”包月业务，每月下发一张权益兑换券，权益兑换券30天有效，过期失效。可兑换腾讯视频VIP月卡、爱奇艺VIP月卡等多个热门权益其中一种权益
    (未退订状态下，该权益连续下发12个月，12个月之后如用户未退订也不再下发权益兑换券；如发生退订，则次月起不再发放该权益兑换券。举例:
    用户于2024年1月份订购，未退订状态下可连续领取权益至2024年12月份，如用户于2024年2月份退订则3月份不再发放该权益。）
    热门会员如下:腾讯视频VIP月卡、优酷视频黄金会员月卡、爱奇艺黄金会员月卡、芒果TV会员月卡、哗哩哗哩会员月卡、QQ音乐绿钻豪华版月卡、酷狗音乐豪华VIP月卡、饿了么超级会员月卡、喜马拉雅VIP月卡、百度网盘会员月卡、
    </br>

    权益二:如用户未退订“沃悦读-5G-畅听好声音20元包”包月业务，每月可领5元话费，未退订状态下连续可领4个月，4个月之后如用户未退订也不可再领5元话费。如用户退订“沃悦读-5G-畅听好声音20元包”包月业务，次月起则不可再领5元话费。（举例：用户1月订购本产品且2月、3月、4月持续订购本产品，则1-4月每月可领取5元话费，5月用户持续订购本产品也不可再领5元话费。）话费领取有效期自订购之日起30天内有效，过期失效。</br>

    权益三:体验AI留声机，三步完成AI语音合成，完美复制个人音色，一键文字转音频。订购产品即可免费使用AI留声功能。</br>

    <span class="colorTitle">4、权益使用方式:</span></br>
    热门会员及5元话费兑换方式: 关注微信公众号“沃要省钱”，点击底部菜单栏“运营商”—“运营商权益领取”，领取用户喜爱的月会员权益和5元话费，领取成功后前往各APP开启会员特权之路。话费领取成功后请关注充值到账短信。
    AI留声机使用方式：微信搜索“联通爱听”微信公众号，点击最下方“会员福利”-“免费录音”，即可使用本功能。</br>

    <span class="colorTitle">5、注意事项:</br>
    (1) 本活动业务为自动续费产品，用户订购期内不退订，下月将进行自动扣费续订。每个手机号码在已开通状态下，暂不支持重复订购本活动业务。</br>
    (2)
    成功开通“沃悦读-5G-畅听好声音20元包”包月业务，每月下发一张权益兑换券，未退订状态下可连续下发12个月，12个月之后如用户未退订也不再下发权益兑换券。如用户当月退订“沃悦读-5G-畅听好声音20元包”，次月起不再下发权益兑换券。权益兑换券当月有效，过期失效。</br>
    (3) 需用户主动去相应平台兑换热门会员和相应面值的话费。每月下发的权益兑换券30天有效，如未按时领取导致过期失效。</br>
    (4)如活动期内相关热门权益因库存不足或其他原因暂时无法兑换，用户可致电客服热线选择当月内延后兑换或者进行其他等价值的权益兑换。</br>
    (5) 本业务当月退订后，从次月起不再享有课程观看权限和领取热门权益，已产生的业务订购费用不予退还;</br>
    (6) 使用相关权益时，请遵守相应平台的用户服务协议和权益使用协议，如违反相关协定规定，沃悦读和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。</span></br>

    <span class="colorTitle">6、退订方式:</span>登陆中国联通APP-点击【服务】点击【号卡套餐】-点击【已订业务】-点击“该活动业务名称【退订】按钮，进行退订。本活动业务支持随时退订，次月生效不再扣费，退订当月仍可享受各项福利。</br>

    <span class="colorTitle">7、订购关系查询:</span>登陆中国联通APP-点击【服务】-点击【号卡套餐】-点击【已订业务】，即可查询。</br>

    8、如有任何问题，请咨询客服热线: <span class="colorTitle">4006022088</span>，客服时间: 周一至周五 8:30-12:00、14:00-18:00。周末及节日正常休息，望谅解。</br>

    9、此产品归属联通沃悦读科技文化有限公司。</br>
  `,
  //  沃悦读-5G-畅听好声音20元包规则
  guize16: `
  <span class="colorTitle">1、活动时间:2024年4月17日-2024年12月31日</span></br>
  <span class="colorTitle">2、活动对象:</span>中国联通2G/3G/4G/5G套餐手机用户</br>
  <span class="colorTitle">3、活动内容:</span>活动期间凡首次成功开通中国联通“沃阅读钻石会员15元包”业务 (每月15元) ，即可获得以下权益：</br>
  权益一：如用户未退订“沃阅读钻石会员15元包”包月业务，每月下发一张权益兑换券，权益兑换券30天有效，过期失效。可兑换腾讯视频VIP月卡、爱奇艺VIP月卡等多个热门权益其中一种权益 未退订状态下，该权益连续下发12个月，12个月之后如用户未退订也不再下发权益兑换券；如发生退订，则次月起不再发放该权益兑换券。</br>
  权益二：如用户未退订“沃阅读钻石会员15元包”包月业务，每月可领5元话费，未退订状态下连续可领3个月，3个月之后如用户未退订也不可再领5元话费。如用户退订“沃阅读钻石会员15元包”包月业务，次月起则不可再领5元话费。话费领取有效期自订购之日起30天内有效，过期失效。</br>
  权益三:体验AI留声机，三步完成AI语音合成，完美复制个人音色，一键文字转音频。订购产品即可免费使用AI留声功能。</br>
  <span class="colorTitle">4、权益使用路径：</span></br>
  热门会员及5元话费兑换方式: 关注微信公众号“沃要省钱”，点击底部菜单栏“运营商”—“运营商权益领取”，领取用户喜爱的月会员权益和5元话费，领取成功后前往各APP开启会员特权之路。话费领取成功后请关注充值到账短信。 AI留声机使用方式：微信搜索“联通爱听”微信公众号，点击菜单栏“爱听”-“免费录音”，开始体验。</br>
  <span class="colorTitle">5、包月资源使用路径：</span>下载并登录联通爱听APP-点击“我的”-“已购买”-选择对应产品-使用；或关注“联通爱听”微信公众号，点击菜单栏“爱听”-“书城”-“我的”-“包月”列表-选择所订购产品-使用。</br>
  <span class="colorTitle">6、注意事项:</br>
  (1) 需用户主动去相应平台兑换热门会员和相应面值的话费。每月下发的权益兑换券30天有效，如未按时领取导致过期失效。</br>
  (2) 本业务当月退订后，从次月起不再享有课程观看权限和领取热门权益，已产生的业务订购费用不予退还。</span></br>
  <span class="colorTitle">7、订购查询:</span>本活动业务为自动续费产品，用户订购期内不退订，下月将进行自动扣费续订。订购关系查询路径：下载并登陆联通爱听APP-我的-已购买-包月，即可查询。</br>
  <span class="colorTitle">8、退订方式:</span>关注“联通爱听”微信公众号-点击菜单栏“我的”-“我的已购”-退订；或拨打电话4006022088进行退订；</br>
  9、如有任何问题，请咨询客服热线: <span class="colorTitle">4006022088</span>，客服时间: 周一至周五 8:30-12:00、14:00-18:00。周末及节日正常休息，望谅解。</br>
  10、此产品归属联通沃悦读科技文化有限公司。</br>
  `,
  //  沃悦读-5G-畅听好声音20元包规则
  guize17: `
    1、活动对象:2G/3G/4G/5G移动运营商用户。</br>
    2、活动内容:活动期间凡首次在本页面成功开通业务(业务名称:	，业务资费:10元/月)，即可获得成长伙伴VIP权益+爆款会员月卡20选1。 爆款会员(20选1)：芒果TV、腾讯视频、爱奇艺、优酷、哔哩、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、夸克网盘超级会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS稻壳会员、饿了么、趣配音。</br>
    3、礼品发放:</br>
    (1)成长伙伴权益领取方式:前往应用商城下载成长伙伴APP登录即可使用。(会员享有的具体权益内容以成长伙伴客户端关于会员特权具体描述为准</br>
    (2)爆款月卡会员权益兑换方式:点击进入<a class="cls_a_fuli"
    href="https://m.woread.diamond.aizhishifm.cn/benefit.html">福利兑换中心</a> -选择您需要的月卡会员权益进行兑换，兑换成功后可前往权益所在平台激活使用；温馨提示：使用相关权益时，请遵守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。</br>
    4、产品退订:开通包月会员后，会在会员到期前自动续费会员。自动续费可随时取消，如需取消，可致电4008888202客服热线协助退订。</br>
    5、注意事项:</br>
    (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。</br>
    (2)本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放(福利礼品包括会员权益等)</br>
    (3)已开通本业务的老用户不可再次参与本次推广活动。</br>
    (4)成功开通本业务，获取的权益福利不超过12个月，权益领取周期为30天，热门权益需用户手动领取如当月热门权益未领取，次月无法补领上月权益，请及时领取。</br>
    6、如有疑问可致电客服热线:4008888202.</br>
  `,
  guize18: `
  1、产品名称：咪咕短剧精品包</br>
  2、产品资费：话费支付10元 /月</br>
  <span class="colorTitle">3、产品对象：中国移动用户</br></span>
  <span class="colorTitle">4、生效时间：订购后立即生效，不取消按月自动续订、续费；可随时取消，取消立即生效，当前周期内可继续享受权益，次月起不再续订。</br></span>
  5、产品内容：活动期间凡首次在本页面成功开通业务(业务名称：咪咕短剧精品包，业务资费：10元/月)，即可获得咪咕短剧精品包权益(业务包内精品短剧随心畅看，免广告，点播折扣)，并赠送10元美团外卖代金券(若次月续订业务，权益仍会发放，不续订则不发放)。</br>
  6、权益领取：</br>
  （1）咪咕短剧精品包权益领取方式：本产品订购生效后，使用订购手机号登录咪咕阅读APP-找到“短剧频道”-“短剧精品包”专区，即可享受权益。</br>
  （2）10元美团外卖代金券兑换方式：</br>
  方式一:点击进入<a
  href="https://migutg.chinaso.com/benefitGs.html?bllProductCode=MIGU_CMCC_DJJP_1000&packageCode=PC10MT">福利兑换中心</a>，选择“代金券权益-美团外卖”，选择10元代金券，输入订购手机号领取；</br>
  方式二：进入中国搜索APP，在“我的-活动中心”，点击咪咕短剧精品包权益领取，进入福利兑换中心，选择相应的代金券权益输入订购手机号领取。</br>
  方式三：业务订购成功后，在下发的权益短信中点击相关链接进入福利兑换中心，选择相应的代金券权益输入订购手机号领取。
  权益领取成功后，即可前往美团外卖APP使用。</br>
  7、办理须知：订购业务前请仔细阅读活动规则，订购本业务，即视为同意接受本业务及其相关活动的所有条款或规则。</br>
  8、退订方式：咪咕阅读APP-[我的]-[我的包月]-[自动续费管理]退订;或致电客服热线4008888202协助退订。</br>
  9、热门会员权益需用户月底前领取，用户需至指定领取入口领取权益，按自然月每月可领取一次权益。会员有效期30天，从领取当天开始计算。如当月权益未领取，次月无法补领。</br>
  10、客服咨询：更多详情请拨打客服热线4008888202。客服工作时间：周一到周日8:00-21:00。</br>
  
  `,
  guize18D: `
  1、产品名称：咪咕短剧精品包</br>
  2、产品资费：话费支付10元 /月</br>
  <span class="colorTitle">3、产品对象：该产品仅支持广东移动用户，</span>终端为安卓系统且开通VoLTE功能的用户办理使用。以下情况不能参与本活动：非广东移动客户、2G/3G不限量套餐客户、4G随心王套餐客户、和多号副号、一卡双号副号、家庭服务计划副号、极光宽带副号、万能副卡等附属号码客户、集团代付/对公托收（如政企动力100行业卡）客户、集团全额统付号码、万花筒客户、手机状态异常（停机、销户、欠费、转品牌未生效等状态）号码、及其它限制办理增值业务的套餐套卡等客户。如需办理，需先主动取消以上套餐生效后方可办理。</br>
  <span class="colorTitle">4、生效时间：订购后立即生效，按月扣费。不取消按月自动续订、续费；可随时取消，取消立即生效，当前周期内可继续享受权益，次月起不再续订。</br></span>
  5、产品内容：活动期间凡首次在本页面成功开通业务(业务名称:咪咕短剧精品包，业务资费:10元/月)，即可获得咪咕短剧精品包权益，并赠送10元美团外卖代金券(若次月续订业务，权益仍会发放，不续订则不发放)。</br>
  6、权益领取：</br>
  （1）咪咕短剧精品包权益领取方式：本产品订购生效后，使用订购手机号登录咪咕阅读APP-找到“短剧频道”-“短剧精品包”专区，即可享受权益。</br>
  （2）10元美团外卖代金券兑换方式：</br>
  方式一:点击进入<a
  href="https://migutg.chinaso.com/benefitGs.html?bllProductCode=MIGU_CMCC_DJJP_1000&packageCode=PC10MT">福利兑换中心</a>，选择“代金券权益-美团外卖”，选择10元代金券，输入订购手机号领取。</br>
  方式二：进入中国搜索APP，在“我的-活动中心”，点击咪咕短剧精品包权益领取，进入福利兑换中心，选择相应的代金券权益输入订购手机号领取。</br>
  方式三：业务订购成功后，在下发的权益短信中点击相关链接进入福利兑换中心，选择相应的代金券权益输入订购手机号领取。
  权益领取成功后，即可前往美团外卖APP使用。</br>
  7、办理须知：订购业务前请仔细阅读活动规则，订购本业务，即视为同意接受本业务及其相关活动的所有条款或规则。</br>
  8、退订方式：咪咕阅读APP-[我的]-[我的包月]-[自动续费管理]退订;或致电客服热线4008888202协助退订。</br>
  9、热门会员权益需用户月底前领取，用户需至指定领取入口领取权益，按自然月每月可领取一次权益。会员有效期30天，从领取当天开始计算。如当月权益未领取，次月无法补领。</br>
  10、客服咨询：更多详情请拨打客服热线4008888202。客服工作时间：周一到周日8:00-21:00。</br>
  
  `,
  guize19: `
  1、产品名称：咪咕短剧新品包</br>
  2、产品资费：话费支付20元 /月</br>
  <span class="colorTitle">3、产品对象：中国移动用户</br></span>
  <span class="colorTitle">4、生效时间：订购后立即生效，不取消按月自动续订、续费；可随时取消，取消立即生效，当前周期内可继续享受权益，次月起不再续订。</br></span>
  5、产品内容：活动期间凡首次在本页面成功开通业务(业务名称：咪咕短剧新品包，业务资费：20元/月)，即可获得咪咕短剧新品包权益(业务包内新品短剧随心畅看，免广告，点播折扣)，并可享饿了么会员月卡(若次月续订业务，权益仍会发放，不续订则不发放)。</br>
  6、权益领取：</br>
  （1）咪咕短剧新品包权益领取方式：本产品订购生效后，使用订购手机号登录咪咕阅读APP-找到“短剧频道”-“短剧新品包”专区，即可享受权益。</br>
  （2）饿了么会员月卡领取方式：</br>
  方式一:点击进入<a
  href="https://migutg.chinaso.com/benefit.html">福利兑换中心</a>，选择“生活娱乐-饿了么”，输入订购手机号领取。</br>
  方式二：进入中国搜索APP，在“我的-活动中心”，点击咪咕短剧新品包权益领取，进入福利兑换中心，选择相应的饿了么权益，输入订购手机号领取。</br>
  方式三：业务订购成功后，在下发的权益短信中点击相关链接进入福利兑换中心，选择相应的饿了么权益，输入订购手机号领取。
  权益领取成功后，即可前往饿了么APP使用。</br>
  7、办理须知：订购业务前请仔细阅读活动规则，订购本业务，即视为同意接受本业务及其相关活动的所有条款或规则。</br>
  8、退订方式：咪咕阅读APP-[我的]-[我的包月]-[自动续费管理]退订;或致电客服热线4008888202协助退订。</br>
  9、热门会员权益需用户月底前领取，用户需至指定领取入口领取权益，按自然月每月可领取一次权益。会员有效期30天，从领取当天开始计算。如当月权益未领取，次月无法补领。</br>
  10、客服咨询：更多详情请拨打客服热线4008888202。客服工作时间：周一到周日8:00-21:00。</br>
  `,
  guize19E: `
  1、产品名称：咪咕短剧新品包</br>
  2、产品资费：话费支付20元 /月</br>
  <span class="colorTitle">3、产品对象：该产品仅支持广东移动用户，</span>终端为安卓系统且开通VoLTE功能的用户办理使用。以下情况不能参与本活动：非广东移动客户、2G/3G不限量套餐客户、4G随心王套餐客户、和多号副号、一卡双号副号、家庭服务计划副号、极光宽带副号、万能副卡等附属号码客户、集团代付/对公托收（如政企动力100行业卡）客户、集团全额统付号码、万花筒客户、手机状态异常（停机、销户、欠费、转品牌未生效等状态）号码、及其它限制办理增值业务的套餐套卡等客户。如需办理，需先主动取消以上套餐生效后方可办理。</br>
  <span class="colorTitle">4、生效时间：订购后立即生效，按月扣费。不取消按月自动续订、续费；可随时取消，取消立即生效，当前周期内可继续享受权益，次月起不再续订。</br></span>
  5、产品内容：活动期间凡首次在本页面成功开通业务(业务名称:咪咕短剧新品包，业务资费:20元/月)，即可获得咪咕短剧新品包权益，并可享饿了么会员月卡(若次月续订业务，权益仍会发放，不续订则不发放)。</br>
  6、权益领取：</br>
  （1）咪咕短剧新品包权益领取方式：本产品订购生效后，使用订购手机号登录咪咕阅读APP-找到“短剧频道”-“短剧新品包”专区，即可享受权益。</br>
  （2）饿了么会员月卡领取方式：</br>
  方式一:点击进入<a
  href="https://migutg.chinaso.com/benefit.html">福利兑换中心</a>，选择“生活娱乐-饿了么”，输入订购手机号领取。</br>
  方式二：进入中国搜索APP，在“我的-活动中心”，点击咪咕短剧新品包权益领取，进入福利兑换中心，选择相应的饿了么权益，输入订购手机号领取。</br>
  方式三：业务订购成功后，在下发的权益短信中点击相关链接进入福利兑换中心，选择相应的饿了么权益，输入订购手机号领取。
  权益领取成功后，即可前往饿了么APP使用。</br>
  7、办理须知：订购业务前请仔细阅读活动规则，订购本业务，即视为同意接受本业务及其相关活动的所有条款或规则。</br>
  8、退订方式：咪咕阅读APP-[我的]-[我的包月]-[自动续费管理]退订;或致电客服热线4008888202协助退订。</br>
  9、热门会员权益需用户月底前领取，用户需至指定领取入口领取权益，按自然月每月可领取一次权益。会员有效期30天，从领取当天开始计算。如当月权益未领取，次月无法补领。</br>
  10、客服咨询：更多详情请拨打客服热线4008888202。客服工作时间：周一到周日8:00-21:00。</br>
  `,
  //移动6元
  guize20: `
   1、活动对象：未开通过该业务的用户<br />
   2、活动内容：订购本活动业务(每月6元，具体以页面、验证码短信中资费为准)。订购立即生效，凡成功开通视频彩铃订阅包的用户，即可享受本专区视频彩铃无限次随心换。<br />
   3、产品简介：本产品为视频彩铃会员产品，订购成功后，当别人给你打电话时，即可观看到一段您设置的视频。演唱会、体育赛事、 明星MV、萌宠等多种短视频供选择。<br />
   4、奖品兑换时间规则：<br />
   月月领：用户订购成功后，每月可前往公众号兑换一次奖品，订购当月可领取会员权益卡(退订后不可兑换，数里有限领完即止)。<br />
   每月热门视频会员5选1：用户可凭订购手机号码领取5款热门视频会员权益中的任意一款。会员权益包括是:腾讯视频会员(周卡)、芒果TV会员(周卡)、爱奇艺黄金会员(周卡)、优酷视频会员(周卡)、搜狐视频会员(周卡)<br />
   5、礼品发放：权益兑换流程：进入微信公众号“可蚁兑”-点击底部菜单栏的“我要兑换”-进入会员兑换中心，选择您需要的会员权益，点击“立即兑换”即可兑换成功，可前往权益所在平台激活使用。订购视频彩铃订阅包的用户，可在业务办理成功后根据订单页面提示关注微信公众号“可蚁兑”，进入公众号后，点击右下方菜单栏“精彩活动”中的二级菜单“视频彩铃中心”，用订购手机号码登录后即可随心设置及更换视频彩铃。<br />
   6、温馨提示：<br />
   (1)每个手机号仅限参与一次，若当月不退订，次月自动扣除包月费用。<br />
   (2)使用相关权益时，请遵守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。<br />
   (3)成功开通本业务，获取的权益福利不超过12个月，权益周期为自然月。每月热门权益需用户手动领取，如当权益未领取，次月无法补领上月权益，请及时领取。<br />
   (4)已开通过业务的老用户不可再次参与本次推广活动。<br />
   (5)用户可登录运营商APP设置和管理自己的视频彩铃内容。<br />
   (6)给你拨打电话的朋友手机需打开VLTE功能，且使用支持视频彩铃的终端手机才能观看到你设置的视频彩铃内容。<br />
   (7)移动用户开通视频彩铃，联通用户拨打将不展示视频彩铃，即三网用户目前暂不互通:4G和5G终端均可使用。<br />
   7、活动咨询：如有疑问可致电客服热线:4006588886，亦可联系微信公众号“可蚁兑”进行咨询。移动用户可编辑短信0000发到至10086退订。<br />
   `,
  guize21: `
   1、2023.7.1-2024.12.31,中国电信用户通过各省电信线上渠道、互联网线上渠道参与本活动。订购“天翼云游戏19.9元包月”产品，资费19.9元/月，连续包月。开通当月按日折算进行收费，次月续订全额收费，退订当月正常收费。<br />
   2、订购成功后如不退订，享有天翼云游戏时长13小时/月权益，自订购起24个月内每月可在10款互联网权益中任选1款领取，最多领取24个月。用户第25个月如续订，继续享有天翼云游戏13小时/月权益，但不再享有领取互联网会员权益。<br />
   3、互联网权益领取说明：当月不领取则作废，不结转与转赠。领取成功后权益自动下发，用户确保输入的手机号为订购成功的手机号，并且手机号准确无误，权益领取成功后，用户可以用手机号登录对应领取权益产品客户端查看并享受会员权益。<br />
   4、互联网权益领取方式：<br />
   方式一：用户点击<a class="cls_a_fuli"
   href="https://act.play.cn/hd/p/gather/">https://act.play.cn/hd/p/gather/</a>进入权益领取页面，在页面上使用订购手机号登录后任选1款互联网权益并领取。<br />
   方式二：用户关注”天翼云游戏”微信公众号，进入公众号底部的菜单“热门活动"-“权益领取”，在页面上使用订购手机号登录后任选1款互联网权益并领取。<br />
   方式三：用户使用订购手机号登录天翼云游戏移动端(安卓用户)或天翼炫游移动端(苹果用户)，点击“头像”-“权益”栏目，任选1款互联网权益并领取。<br />
   5、退订方式：用户可拨打10000号或4008689689办理退订，取消后天翼云游戏19.9元包月次月生效，互联网会员权益领取截止到退订当月月底。用户如退订将在12个月内不能重复参加活动。<br />
   6、对活动如有任何疑问，可拨打天翼云游戏客服热线4008689689咨询，客服时间:周一至周日9:00-22:00。<br />
   `,
  guize22: `
   <span class="colorTitle">1、活动时间:2024年5月1日-2025年4月31日</span></br>
   <span class="colorTitle">2、活动对象:</span>中国联通2G/3G/4G/5G套餐手机用户</br>
   <span class="colorTitle">3、活动内容:</span>活动期间凡首次成功开通中国联通“沃阅读-人文历史课-包月19元”业务 (每月19元) ，即可获得以下权益：</br>
   权益一：如用户未退订“沃阅读-人文历史课-包月19元”包月业务，每月下发一张权益兑换券，权益兑换券30天有效，过期失效。可兑换腾讯视频VIP月卡、爱奇艺VIP月卡等多个热门权益其中一种权益 。未退订状态下，该权益连续下发12个月，12个月之后如用户未退订也不再下发权益兑换券；如发生退订，则次月起不再发放该权益兑换券。</br>
   权益二:如用户未退订“沃阅读-人文历史课-包月19元”包月业务，每月可领5元话费，未退订状态下连续可领4个月，4个月之后如用户未退订也不可再领5元话费。如用户退订“沃阅读-人文历史课-包月19元”包月业务，次月起则不可再领5元话费。话费领取有效期自订购之日起30天内有效，过期失效。</br>
   权益三:体验AI留声机，三步完成AI语音合成，完美复制个人音色，一键文字转音频。订购产品即可免费使用AI留声功能。</br>
   <span class="colorTitle">4、权益使用路径：</span></br>
   热门会员及5元话费兑换路径: 关注微信公众号“沃要省钱”，点击底部菜单栏“运营商”—“运营商权益领取”，使用订购成功的手机号码领取用户喜爱的月会员权益和5元话费，领取成功后前往各APP开启会员特权之路。话费领取成功后请关注充值到账短信。 AI留声机使用路径：关注“联通爱听”微信公众号，点击菜单栏“爱听”-“免费录音”，开始体验。</br>
   <span class="colorTitle">5、包月资源使用路径：</span>下载并登录联通爱听APP-点击“我的”-“已购买”-选择对应产品-使用；或关注“联通爱听”微信公众号，点击菜单栏“爱听”-“书城”-“我的”-“包月”列表-选择所订购产品-使用。</br>
   <span class="colorTitle">6、注意事项:</span></br>
   <span class="colorTitle">(1)需用户主动去相应平台兑换热门会员和相应面值的话费。每月下发的权益兑换券30天有效，如未按时领取导致过期失效。</br>
   (2) 本业务当月退订后，从次月起不再享有包月资源使用权限和领取热门权益权限，已产生的业务订购费用不予退还。</span></br>
   <span class="colorTitle">7、订购查询:</span>本活动业务为自动续费产品，用户订购期内不退订，下月将进行自动扣费续订。订购关系查询路径：下载并登陆联通爱听APP-我的-已购买-包月，即可查询。</br>
   <span class="colorTitle">8、退订方式:</span>关注“联通爱听”微信公众号-点击菜单栏“我的”-“我的已购”-退订；或拨打电话4006022088进行退订；</br>
   9、如有任何问题，请咨询客服热线: <span class="colorTitle">4006022088</span>，客服时间: 周一至周五 8:30-12:00、14:00-18:00。周末及节日正常休息，望谅解。</br>
   10、此产品归属联通沃悦读科技文化有限公司。</br>
   `,
  guize23: `
   <span class="colorTitle">1、活动时间:2024年5月1日-2025年4月31日</span></br>
   <span class="colorTitle">2、活动对象:</span>中国联通2G/3G/4G/5G套餐手机用户</br>
   <span class="colorTitle">3、活动内容:</span>活动期间凡首次成功开通中国联通“名师带你读14.9元包”业务 (每月14.9元) ，即可获得以下权益：</br>
   权益一:如用户未退订“名师带你读14.9元包”包月业务，每月下发一张权益兑换券，权益兑换券30天有效，过期失效。可兑换腾讯视频VIP月卡、爱奇艺VIP月卡等多个热门权益其中一种权益 。未退订状态下，该权益连续下发12个月，12个月之后如用户未退订也不再下发权益兑换券；如发生退订，则次月起不再发放该权益兑换券。</br>
   权益二:如用户未退订“名师带你读14.9元包”包月业务，每月可领5元话费，未退订状态下连续可领3个月，3个月之后如用户未退订也不可再领5元话费。如用户退订“名师带你读14.9元包”包月业务，次月起则不可再领5元话费。话费领取有效期自订购之日起30天内有效，过期失效。</br>
   权益三:体验AI留声机，三步完成AI语音合成，完美复制个人音色，一键文字转音频。订购产品即可免费使用AI留声功能。</br>
   <span class="colorTitle">4、权益使用路径：</span></br>
   热门会员及5元话费兑换路径: 关注微信公众号“沃要省钱”，点击底部菜单栏“运营商”—“运营商权益领取”，使用订购成功的手机号码领取用户喜爱的月会员权益和5元话费，领取成功后前往各APP开启会员特权之路。话费领取成功后请关注充值到账短信。 AI留声机使用路径：关注“联通爱听”微信公众号，点击菜单栏“爱听”-“免费录音”，开始体验。</br>
   <span class="colorTitle">5、包月资源使用路径：</span>下载并登录联通爱听APP-点击“我的”-“已购买”-选择对应产品-使用；或关注“联通爱听”微信公众号，点击菜单栏“爱听”-“书城”-“我的”-“包月”列表-选择所订购产品-使用。</br>
   <span class="colorTitle">6、注意事项:</br>
   (1)需用户主动去相应平台兑换热门会员和相应面值的话费。每月下发的权益兑换券30天有效，如未按时领取导致过期失效。</br>
   (2)本业务当月退订后，从次月起不再享有包月资源使用权限和领取热门权益权限，已产生的业务订购费用不予退还。</span></br>
   <span class="colorTitle">7、订购查询:</span>本活动业务为自动续费产品，用户订购期内不退订，下月将进行自动扣费续订。订购关系查询路径：下载并登陆联通爱听APP-我的-已购买-包月，即可查询。</br>
   <span class="colorTitle">8、退订方式:</span>关注“联通爱听”微信公众号-点击菜单栏“我的”-“我的已购”-退订；或拨打电话4006022088进行退订；</br>
   9、如有任何问题，请咨询客服热线: <span class="colorTitle">4006022088</span>，客服时间: 周一至周五 8:30-12:00、14:00-18:00。周末及节日正常休息，望谅解。</br>
   10、此产品归属联通沃悦读科技文化有限公司。</br>
   `,
  guize24: `
   <span class="colorTitle">1、活动时间:2024年5月1日-2025年4月31日</span></br>
   <span class="colorTitle">2、活动对象:</span>中国联通2G/3G/4G/5G套餐手机用户</br>
   <span class="colorTitle">3、活动内容:</span>活动期间凡首次成功开通中国联通“沃阅读大咖精选课14.9元包”业务 (每月14.9元) ，即可获得以下权益：</br>
   权益一:如用户未退订“沃阅读大咖精选课14.9元包”包月业务，每月下发一张权益兑换券，权益兑换券30天有效，过期失效。可兑换腾讯视频VIP月卡、爱奇艺VIP月卡等多个热门权益其中一种权益 。未退订状态下，该权益连续下发12个月，12个月之后如用户未退订也不再下发权益兑换券；如发生退订，则次月起不再发放该权益兑换券。</br>
   权益二:如用户未退订“沃阅读大咖精选课14.9元包”包月业务，每月可领5元话费，未退订状态下连续可领3个月，3个月之后如用户未退订也不可再领5元话费。如用户退订“沃阅读大咖精选课14.9元包”包月业务，次月起则不可再领5元话费。话费领取有效期自订购之日起30天内有效，过期失效。</br>
   权益三:体验AI留声机，三步完成AI语音合成，完美复制个人音色，一键文字转音频。订购产品即可免费使用AI留声功能。</br>
   <span class="colorTitle">4、权益使用路径：</span></br>
   热门会员及5元话费兑换路径: 关注微信公众号“沃要省钱”，点击底部菜单栏“运营商”—“运营商权益领取”，使用订购成功的手机号码领取用户喜爱的月会员权益和5元话费，领取成功后前往各APP开启会员特权之路。话费领取成功后请关注充值到账短信。 AI留声机使用路径：关注“联通爱听”微信公众号，点击菜单栏“爱听”-“免费录音”，开始体验。</br>
   <span class="colorTitle">5、包月资源使用路径：</span>下载并登录联通爱听APP-点击“我的”-“已购买”-选择对应产品-使用；或关注“联通爱听”微信公众号，点击菜单栏“爱听”-“书城”-“我的”-“包月”列表-选择所订购产品-使用。</br>
   <span class="colorTitle">6、注意事项:</br>
   (1)需用户主动去相应平台兑换热门会员和相应面值的话费。每月下发的权益兑换券30天有效，如未按时领取导致过期失效。</br>
   (2)本业务当月退订后，从次月起不再享有包月资源使用权限和领取热门权益权限，已产生的业务订购费用不予退还。</span></br>
   <span class="colorTitle">7、订购查询:</span>本活动业务为自动续费产品，用户订购期内不退订，下月将进行自动扣费续订。订购关系查询路径：下载并登陆联通爱听APP-我的-已购买-包月，即可查询。</br>
   <span class="colorTitle">8、退订方式:</span>关注“联通爱听”微信公众号-点击菜单栏“我的”-“我的已购”-退订；或拨打电话4006022088进行退订；</br>
   9、如有任何问题，请咨询客服热线: <span class="colorTitle">4006022088</span>，客服时间: 周一至周五 8:30-12:00、14:00-18:00。周末及节日正常休息，望谅解。</br>
   10、此产品归属联通沃悦读科技文化有限公司。</br>
   `,
  guize25: `
  1、活动对象:2G/3G/4G/5G移动运营商用户。</br>
  2、活动内容:活动期间凡首次在本页面成功开通业务(业务名称:咪咕短剧畅看包，业务资费:29元/月)，即可获得咪咕短剧畅看包权益，并赠送30元美团代金券，代金券以一张10元，一张20元的形式下发(若次月续订业务，权益仍会发放，不续订则不发放)。</br>
  3、礼品发放</br>
  （1）咪咕短剧畅看包权益领取方式：本产品订购生效后，使用订购手机号登录咪咕阅读APP—找到“短剧频道”-“短剧畅看包”专区，即可享受权益。</br>
  （2）美团代金券兑换方式：</br>
  方式一:点击进入<a
  href="https://migutg.chinaso.com/benefitGs.html?bllProductCode=MIGU_CMCC_DJCK_2900&packageCode=PC30MT">福利兑换中心</a>，选择“代金券权益-美团外卖”，分别选择10元、20元的美团代金券，输入订购手机号领取；兑换成功后，即可前往美团外卖APP使用。</br>
  方式二：进入中国搜索APP，在“我的-活动中心”，点击咪咕短剧畅看包权益领取，进入福利兑换中心，即可选择相应的代金券，输入订购手机号领取。</br>
  方式三：业务订购成功后，在下发的权益短信中点击相关链接进入福利兑换中心，即可选择相应的代金券权益领取。</br>

  4、产品退订: 开通包月会员后，将在会员到期前自动续费会员。 自动续费可随时取消,如需取消,请您在下一次扣费时间前在咪咕阅读APP中“我的-我的订购-包月-管理自动续费”退订，或致电4008888202客服热线协助。</br>
  5、注意事项:</br>
  (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。</br>
  (2)已开通本业务的老用户不可再次参与本次推广活动。</br>
  (3)成功开通本业务，每月美团代金券权益随业务订购情况下发，若退订本业务则视为放弃本活动福利，未兑换的美团代金券权益将不再发放。</br>
  (4)权益发放12个月，业务订满12个月后续订仍享有咪咕短剧畅看包权益，美团代金券权益将不再发放。美团代金券权益需用户手动领取，若当月权益未领取，次月无法补领上月权益，请及时领取。</br>
  6、如有疑问可致电客服热线:4008888202。</br>
  `,
  guize26: `
    1、活动对象：2G/3G/4G/5G手机用户</br>
    2、活动内容:活动期间凡首次在本页面成功开通业务（业务资费：14.9~20元/月，具体资费以短信为准）即可获得以下权益：</br>
      权益一：如用户未退订沃阅读包月业务，每月下发一张权益兑换券，权益兑换券30天有效，过期失效。可兑换腾讯视频VIP月卡、爱奇艺VIP月卡、优酷VIP月卡等20个热门会员月卡权益其中任意一款权益。未退订状态下，该权益连续下发12个月，12个月之后如用户未退订也不再下发权益兑换券；如发生退订，则次月起不再发放该权益兑换券。</br>
      权益二：每月可领5元话费，未退订状态下连续可领4个月，4个月之后如用户未退订也不可再领5元话费。</br>
    3、礼品发放</br>
    （1）热门会员月卡权益兑换方式：点击进入<a  href="https://m.woread.diamond.aizhishifm.cn/benefit.html">福利兑换中心</a>,选择您需要的会员权益-点击“立即兑换”即可兑换成功，可前往权益所在平台激活使用；</br>
    （2）话费领取方式：点击进入福利中心,选择您需要充值的话费-点击“立即兑换”即可兑换成功，兑换成功后3个工作日内系统自动充值到账；</br>
    温馨提示：使用相关权益时，请遵守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。</br>
    4、注意事项:</br>
    (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。</br>
    (2)本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放（福利礼品包括会员、代金券、话费等）；</br>
    (3)已开通本业务的老用户不可再次参与本次推广活动。</br>
    (4)权益领取周期为自然月，热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。</br>
    5、联通退订方式：进入中国联通APP-我的-我已订购-增值业务-选择相关业务名称-退订，或者发送短信0000至10010，按提示内容退订；</br>
    6、如有疑问可致电客服热线:4008888202。</br>
   `,
  guize27: `
    1、活动对象:2G/3G/4G/5G移动运营商用户。</br>
    2、活动内容:活动期间凡首次在本页面成功开通业务(业务名称:智慧课堂伴学业务，业务资费:20元/月)，即可获得智慧课堂VIP权益+爆款会员月卡20选1。 爆款会员(20选1)：芒果TV、腾讯视频、爱奇艺、优酷、哔哩、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、夸克网盘超级会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS超级会员、饿了么、趣配音。</br>
    3、礼品发放:</br>
    (1)智慧课堂会员权益领取方式:关注”湖南移动智慧校园”微信公众号或前往应用商城下载登录湖南和教育手机客户端登录，找到智慧课堂伴学模块即可使用。(会员享有的具体权益内容以湖南和教育-智慧课堂伴学业务关于会员特权具体描述为准</br>
    (2)爆款月卡会员权益兑换方式:点击进入<a class="cls_a_fuli"
    href="https://m.woread.diamond.aizhishifm.cn/benefitGs.html?bllProductCode=CMCC_MPZHKTBX2000&packageCode=PC201">福利兑换中心</a> -选择您需要的月卡会员权益进行兑换，兑换成功后可前往权益所在平台激活使用；温馨提示：使用相关权益时，请遵守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。</br>
    4、产品退订:开通包月会员后，会在会员到期前自动续费会员。自动续费可随时取消，如需取消，可致电4008888202客服热线协助退订。</br>
    5、注意事项:</br>
    (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。</br>
    (2)本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放(福利礼品包括会员权益等)</br>
    (3)已开通本业务的老用户不可再次参与本次推广活动。</br>
    (4)成功开通本业务，获取的权益福利不超过12个月，权益领取周期为30天，热门权益需用户手动领取如当月热门权益未领取，次月无法补领上月权益，请及时领取。</br>
    6、如有疑问可致电客服热线:4008888202.</br>
  `,
  guize27B: `
    1、活动对象:2G/3G/4G/5G移动运营商用户。</br>
    2、活动内容：活动期间凡首次在本页面成功开通业务(业务名称：智慧课堂伴学业务，业务资费:15元/月)，即可获得智慧课堂伴学15元权益包+爆款会员月卡20选1。爆款会员(20选1):芒果TV、腾讯视频、爱奇艺、优酷、bilibili、醋狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、夸克网盘超级会员、QQ超级会员、新浪微博、知乎、百度网盘超级会员、WPS超级会员、饿了么、趣配音。</br>
    3、礼品发放:</br>
    (1)智慧课堂伴学包月业务权益领取方式：关注”湖南移动智慧校园”微信公众号或前往应用商城下载登录湖南和教育手机客户端登录，找到智慧课堂伴学模块即可使用，(业务享有的具体权益内容以湖南和教育-智慧课堂伴学业务关于特权具体描述为准。</br>
    (2)爆款月卡会员权益兑换方式：点击进入<a class="cls_a_fuli"
    href="https://m.woread.diamond.aizhishifm.cn/benefitGs.html?bllProductCode=CMCC_MPZHKTBX2000&packageCode=PC201">福利兑换中心</a> -选择您需要的月卡会员权益进行兑换，兑换成功后可前往权益所在平台激活使用；温想提示：使用相关权益时，请进守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。</br>
    4、产品退订：开通包月业务后，会在业务到期前自动续费。自动续费可随时取消，如需取消，可致电4008888202客服热线协助退订。</br>
    5、注意事项:</br>
    (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。</br>
    (2)本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放(福利礼品包括会员权益等)</br>
    (3)已开通本业务的老用户不可再次参与本次推广活动。</br>
    (4)成功开通本业务，获取的权益福利不超过12个月，权益领取周期为30天，热门权益需用户手动领取如当月热门权益未领取，次月无法补领上月权益，请及时领取。</br>
    6、如有疑问可致电客服热线:4008888202.</br>
  `,
  guize27C: `
  1、活动对象:2G/3G/4G/5G移动运营商用户。</br>
  2、活动内容：活动期间凡首次在本页面成功开通业务(业务名称：智慧课堂伴学业务，业务资费:25元/月)，即可获得智慧课堂伴学25元权益包+爆款会员月卡20选1。爆款会员(20选1):芒果TV、腾讯视频、爱奇艺、优酷、bilibili、醋狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、夸克网盘超级会员、QQ超级会员、新浪微博、知乎、百度网盘超级会员、WPS超级会员、饿了么、趣配音。</br>
  3、礼品发放:</br>
  (1)智慧课堂伴学包月业务权益领取方式：关注”湖南移动智慧校园”微信公众号或前往应用商城下载登录湖南和教育手机客户端登录，找到智慧课堂伴学模块即可使用，(业务享有的具体权益内容以湖南和教育-智慧课堂伴学业务关于特权具体描述为准。</br>
  (2)爆款月卡会员权益兑换方式：点击进入<a class="cls_a_fuli"
  href="https://m.woread.diamond.aizhishifm.cn/benefitGs.html?bllProductCode=CMCC_MPZHKTBX2000&packageCode=PC201">福利兑换中心</a> -选择您需要的月卡会员权益进行兑换，兑换成功后可前往权益所在平台激活使用；温想提示：使用相关权益时，请进守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。</br>
  4、产品退订：开通包月业务后，会在业务到期前自动续费。自动续费可随时取消，如需取消，可致电4008888202客服热线协助退订。</br>
  5、注意事项:</br>
  (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。</br>
  (2)本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放(福利礼品包括会员权益等)</br>
  (3)已开通本业务的老用户不可再次参与本次推广活动。</br>
  (4)成功开通本业务，获取的权益福利不超过12个月，权益领取周期为30天，热门权益需用户手动领取如当月热门权益未领取，次月无法补领上月权益，请及时领取。</br>
  6、如有疑问可致电客服热线:4008888202.</br>
`,
  guize28: `
  活动对象:2G/3G/4G/5G移动运营商用户。</br>
  活动内容:活动期间凡首次成功开通联合会员业务（业务资费：10-30，具体业务名称和资费以短信为准），当月即可获得热门福利且订购当月可获得iPhone 15 Pro Max抽奖机会；数量有限，送完即止。
    每月订购权益礼品：</br>
    互联网热门会员月卡多选1：涵盖芒果TV、腾讯视频、爱奇艺、优酷、哔哩哔哩、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、夸克网盘超级会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS超级会员、饿了么、趣配音等平台。</br>
    幸运大抽奖：</br>
    （1）抽奖的奖品包含：热门APP会员月卡（随机）、888话费红包、Airpods pro耳机、iPhone15Pro Max手机等奖品。奖品资源定期更新，实际以页面展示为准，数量有限，先到先得，派完即止。</br>
    （2）中奖概率：</br>
    iPhone15Pro Max手机中奖概率为0.01%，Airpods耳机、888话费红包中奖概率为0.1%，热门APP会员月卡（随机）中奖率为99%。</br>
  
  
  礼品发放:</br>
  (1)每月热门福利兑换方式：点击<a class="cls_a_fuli"
  href="https://m.woread.diamond.aizhishifm.cn/benefit.html">【福利兑换中心】</a>，选择想要兑换的权益进入兑换流程，输入手机号即可兑换成功，可前往权益所在平台激活使用；</br>
  (2)幸运大抽奖兑换方式：当月订购成功用户，进入【沃要省钱】微信公众号，点击底部菜单栏【吃喝玩乐】-【抽奖赢好礼】前往抽奖页面，输入手机号即可参与抽奖。我们将于每月底在公众号【沃要省钱】-【吃喝玩乐】-【中奖名单】公布中奖名单，用户可根据中奖权益，前往兑换相应的权益，888话费红包、耳机、手机类奖品需致电客服电话4008888202人工领取发放。</br>
  注意事项:</br>
  （1）抽奖资格每个手机号仅限参与一次。</br>
  （2）权益未充值到账前退订本业务，视作放弃本活动权益产品，将不再发放。</br>
  （3）已开通过业务的老用户不可再次参与本次抽奖活动。</br>
  如有疑问可致电客服热线:4008888202.</br>
  `,
  guize29: `
  【资费详情】 </br>
  20元游戏娱乐权益包内含第七幻域钻石专享礼包，资费20元/月
  每月可享《第七幻域》钻石*200。 </br>
  【会员权益说明】</br>
  ①权益说明:每月可享《第七幻域》钻石*200，钻石为游戏内的高级
  货币，可以购买高级道具。</br>
  ②权益使用范围:兑换游戏道具礼包适用于《第七幻域》咪咕快游专
  属渠道游戏版本。</br>
  ③权益兑换方式:用户订购成功后，将向订购兑换的手机号码发送兑
  换码，一般半小时内发送，兑换码长期有效。</br>
  ④权益使用方法:用户收到兑换码打开《第七幻域》，点击左上角人物头像，点击兑换码，输入兑换码即可礼包到手</br>
  【订购及生效规则】</br>
    ①本产品按月订购,订购成功立即生效;</br>
    ②因本产品为连续包月的虚拟产品,开通成功后不支持退款;</br>
    ③有效期内产品不支持叠加办理;</br>
  【退订方式】</br>
  有效期内登录“中国移动APP-我的-已订业务”或通过拔打10086进
  行主动退订，退订次月生效，退订后次月不再扣费，到期后第七幻域
  钻石专享礼包会员权益自动失效，次月起不再续订，退订完成前已经
  扣除的费用不予退还和补偿。</br>
  【注意事项】</br>
  ①本活动的参与者(又称“用户”)应为具有完全民事行为能力的自然
  人，未成年人需在其法定监护人的指导及同意下才可以参加。</br>
  ②本活动仅限中国移动用户参加，同一用户仅限使用一个手机号参与
  本次活动且每个用户仅限购买一次，同一手机号同一设备、身份证以
  及其他信息相同的，均视为同一用户我司有权对用户的参与资格进行
  审核，对于审核未通过的用户我司有权取消其参与活动的资格，对已
  获赠的权益我司有权收回。</br>
  ③我司有权根据本活动的实际举办情况对活动规则进行变动或调整;
  由于不可抗因素、网络、作弊等非官方原因导致活动出现异常情况，
  我司有权采取活动调整、暂停等措施。</br>
  ④为向用户提供更好的服务，我司将通过APP、短信、电话邮件、5G
  消息等方式向用户发送业务使用、热点内容、促销活动等信息及进行
  沟通互动。</br>
  ⑤可通过登录https://plus.migu.cn/c/0f57yy,更多权益等你来!</br>
  ⑥在订购时请保证电话畅通不欠费,如有任何疑问请拔打客服电话:
  4008888202.</br>
  `,
  guize30: `
  1、活动对象:2G/3G/4G/5G移动运营商用户。</br>
  2、活动内容:活动期间凡首次在本页面成功开通业务(业务名称:开心作文业务，业务资费:15元/月)，即可获得开心作文包月业务权益+爆款会员月卡20选1。 爆款会员(20选1)：芒果TV、腾讯视频、爱奇艺、优酷、哔哩、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、夸克网盘超级会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS超级会员、饿了么、趣配音。</br>
  3、礼品发放:</br>
  (1)开心作文包月业务权益领取方式:关注”湖南移动智慧校园”微信公众号或前往应用商城下载登录湖南和教育手机客户端登录，找到开心作文业务模块即可使用。(业务享有的具体权益内容以湖南和教育-开心作文业务关于权益特权具体描述为准)。</br>
  (2)爆款月卡会员权益兑换方式:点击进入<a class="cls_a_fuli"
  href="https://m.woread.diamond.aizhishifm.cn/benefit.html">福利兑换中心</a> -选择您需要的月卡会员权益进行兑换，兑换成功后可前往权益所在平台激活使用；温馨提示：使用相关权益时，请遵守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。</br>
  4、产品退订:开通包月会员后，会在会员到期前自动续费会员。自动续费可随时取消，如需取消，可致电4008888202客服热线协助退订。</br>
  5、注意事项:</br>
  (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。</br>
  (2)本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放(福利礼品包括会员权益等)</br>
  (3)已开通本业务的老用户不可再次参与本次推广活动。</br>
  (4)成功开通本业务，获取的权益福利不超过12个月，权益领取周期为30天，热门权益需用户手动领取如当月热门权益未领取，次月无法补领上月权益，请及时领取。</br>
  6、如有疑问可致电客服热线:4008888202.</br>
  `,
  guize31: `
  1、活动对象:2G/3G/4G/5G移动运营商用户。</br>
  2、活动内容:活动期间凡首次在本页面成功开通业务(业务名称:优学作文业务，业务资费:20元/月)，即可获得优学作文包月业务权益+爆款会员月卡20选1。 爆款会员(20选1)：芒果TV、腾讯视频、爱奇艺、优酷、哔哩、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、夸克网盘超级会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS超级会员、饿了么、趣配音。</br>
  3、礼品发放:</br>
  (1)优学作文包月业务权益领取方式:关注”湖南移动智慧校园”微信公众号或前往应用商城下载登录湖南和教育手机客户端登录，找到优学作文业务模块即可使用。(业务享有的具体权益内容以湖南和教育-优学作文业务关于权益特权具体描述为准)。</br>
  (2)爆款月卡会员权益兑换方式:点击进入<a class="cls_a_fuli"
  href="https://m.woread.diamond.aizhishifm.cn/benefit.html">福利兑换中心</a> -选择您需要的月卡会员权益进行兑换，兑换成功后可前往权益所在平台激活使用；温馨提示：使用相关权益时，请遵守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。</br>
  4、产品退订:开通包月会员后，会在会员到期前自动续费会员。自动续费可随时取消，如需取消，可致电4008888202客服热线协助退订。</br>
  5、注意事项:</br>
  (1)每个手机号仅限参与一次，如当月不退订，次月自动续订。</br>
  (2)本业务退订后视作放弃本活动福利礼品，未充值到账或未兑换的礼品将不再发放(福利礼品包括会员权益等)</br>
  (3)已开通本业务的老用户不可再次参与本次推广活动。</br>
  (4)成功开通本业务，获取的权益福利不超过12个月，权益领取周期为30天，热门权益需用户手动领取如当月热门权益未领取，次月无法补领上月权益，请及时领取。</br>
  6、如有疑问可致电客服热线:4008888202.</br>
  `,
  // 沃悦读-5G-畅影尊享版20元包规则
  cyzxb: `
        <span class="colorTitle">1、活动时间:2024年1月10日-2024年12月31日</span></br>
        <span class="colorTitle">2、活动对象:</span>中国联通2G/3G/4G/5G套餐手机用户</br>
        <span class="colorTitle">3、活动内容:</span>活动期间凡首次成功开通中国联通“沃悦读-5G-畅影尊享版20元包”业务 (每月20元) ，即可获得以下权益：</br>
        权益一：如用户未退订“沃悦读-5G-畅影尊享版20元包”包月业务，每月下发一张权益兑换券，权益兑换券30天有效，过期失效。可兑换腾讯视频VIP月卡、爱奇艺VIP月卡等多个热门权益其中一种权益 。未退订状态下，该权益连续下发12个月，12个月之后如用户未退订也不再下发权益兑换券；如发生退订，则次月起不再发放该权益兑换券。<br />
        权益二:如用户未退订“沃悦读-5G-畅影尊享版20元包”包月业务，每月可领5元话费，未退订状态下连续可领4个月，4个月之后如用户未退订也不可再领5元话费。如用户退订“沃悦读-5G-畅影尊享版20元包”包月业务，次月起则不可再领5元话费。话费领取有效期自订购之日起30天内有效，过期失效。</br>
        权益三:体验AI留声机，三步完成AI语音合成，完美复制个人音色，一键文字转音频。订购产品即可免费使用AI留声功能。</br>
        <span class="colorTitle">4、权益使用路径:</span></br>
        热门会员及5元话费兑换路径: 关注微信公众号“沃要省钱”，点击底部菜单栏“运营商”—“运营商权益领取”，使用订购成功的手机号码领取用户喜爱的月会员权益和5元话费，领取成功后前往各APP开启会员特权之路。话费领取成功后请关注充值到账短信。
        AI留声机使用路径：关注“联通爱听”微信公众号，点击菜单栏“爱听”-“免费录音”，开始体验。</br>
        <span class="colorTitle">5、包月资源使用路径：</span>下载并登录联通爱听APP-点击“我的”-“已购买”-选择对应产品-使用；或关注“联通爱听”微信公众号，点击菜单栏“爱听”-“书城”-“我的”-“包月”列表-选择所订购产品-使用。</br>
        <span class="colorTitle">6、注意事项:</br>
        (1)需用户主动去相应平台兑换热门会员和相应面值的话费。每月下发的权益兑换券30天有效，如未按时领取导致过期失效。</br>
        (2) 本业务当月退订后，从次月起不再享有包月资源使用权限和领取热门权益权限，已产生的业务订购费用不予退还。</span></br>
        <span class="colorTitle">7、订购查询：</span>本活动业务为自动续费产品，用户订购期内不退订，下月将进行自动扣费续订。订购关系查询路径：下载并登陆联通爱听APP-我的-已购买-包月，即可查询。</br>

        <span class="colorTitle">8、退订方式:</span>关注“联通爱听”微信公众号-点击菜单栏“我的”-“我的已购”-退订；或拨打电话4006022088进行退订；</br>

        9、如有任何问题，请咨询客服热线: <span class="colorTitle">4006022088</span> ，客服时间: 周一至周五 8:30-12:00、14:00-18:00。周末及节日正常休息，望谅解。</br>

        10、此产品归属联通沃悦读科技文化有限公司。</br>
  `,
  // 艺术鉴赏经典课规则
  ysjs: `
      <span class="colorTitle">1、活动时间:2024年1月10日-2024年12月31日</span></br>
      <span class="colorTitle">2、活动对象:</span>中国联通2G/3G/4G/5G套餐手机用户</br>
      <span class="colorTitle">3、活动内容:</span>活动期间凡首次成功开通中国联通“艺术鉴赏经典课”业务 (每月15元) ，即可获得以下权益：</br>
      权益一：如用户未退订“艺术鉴赏经典课”包月业务，每月下发一张权益兑换券，权益兑换券30天有效，过期失效。可兑换腾讯视频VIP月卡、爱奇艺VIP月卡等多个热门权益其中一种权益 。未退订状态下，该权益连续下发12个月，12个月之后如用户未退订也不再下发权益兑换券；如发生退订，则次月起不再发放该权益兑换券。</br>
      权益二:如用户未退订“艺术鉴赏经典课”包月业务，每月可领5元话费，未退订状态下连续可领3个月，3个月之后如用户未退订也不可再领5元话费。如用户退订“艺术鉴赏经典课”包月业务，次月起则不可再领5元话费。话费领取有效期自订购之日起30天内有效，过期失效。</br>
      权益三:体验AI留声机，三步完成AI语音合成，完美复制个人音色，一键文字转音频。订购产品即可免费使用AI留声功能。</br>
      <span class="colorTitle">4、权益使用路径:</span></br>
      热门会员及5元话费兑换路径: 关注微信公众号“沃要省钱”，点击底部菜单栏“运营商”—“运营商权益领取”，使用订购成功的手机号码领取用户喜爱的月会员权益和5元话费，领取成功后前往各APP开启会员特权之路。话费领取成功后请关注充值到账短信。</br>
      AI留声机使用路径：关注“联通爱听”微信公众号，点击菜单栏“爱听”-“免费录音”，开始体验。</br>
      <span class="colorTitle">5、包月资源使用路径：</span>下载并登录联通爱听APP-点击“我的”-“已购买”-选择对应产品-使用；或关注“联通爱听”微信公众号，点击菜单栏“爱听”-“书城”-“我的”-“包月”列表-选择所订购产品-使用。</br>
      <span class="colorTitle">6、注意事项:</span></br>
      <span class="colorTitle">(1)需用户主动去相应平台兑换热门会员和相应面值的话费。每月下发的权益兑换券30天有效，如未按时领取导致过期失效。</br>
      (2) 本业务当月退订后，从次月起不再享有包月资源使用权限和领取热门权益权限，已产生的业务订购费用不予退还。</span></br>
      <span class="colorTitle">7、订购查询：</span>本活动业务为自动续费产品，用户订购期内不退订，下月将进行自动扣费续订。订购关系查询路径：下载并登陆联通爱听APP-我的-已购买-包月，即可查询。</br>
      <span class="colorTitle">8、退订方式:</span>关注“联通爱听”微信公众号-点击菜单栏“我的”-“我的已购”-退订；或拨打电话4006022088进行退订；</br>
      9、如有任何问题，请咨询客服热线: <span class="colorTitle">4006022088</span>，客服时间: 周一至周五 8:30-12:00、14:00-18:00。周末及节日正常休息，望谅解。</br>
      10、此产品归属联通沃悦读科技文化有限公司。</br>
  `,
  // 科学育儿
  kxye: `
      <span class="colorTitle">1、活动时间:2024年1月10日-2024年12月31日</span></br>
      <span class="colorTitle">2、活动对象:</span>中国联通2G/3G/4G/5G套餐手机用户</br>
      <span class="colorTitle">3、活动内容:</span>活动期间凡首次成功开通中国联通“沃阅读-科学育儿课-包月19元”业务 (每月19元) ，即可获得以下权益：</br>
      权益一：如用户未退订“沃阅读-科学育儿课-包月19元”包月业务，每月下发一张权益兑换券，权益兑换券30天有效，过期失效。可兑换腾讯视频VIP月卡、爱奇艺VIP月卡等多个热门权益其中一种权益 未退订状态下，该权益连续下发12个月，12个月之后如用户未退订也不再下发权益兑换券；如发生退订，则次月起不再发放该权益兑换券。</br>
      权益二：如用户未退订“沃阅读-科学育儿课-包月19元”包月业务，每月可领5元话费，未退订状态下连续可领4个月，4个月之后如用户未退订也不可再领5元话费。如用户退订“沃阅读-科学育儿课-包月19元”包月业务，次月起则不可再领5元话费。话费领取有效期自订购之日起30天内有效，过期失效。</br>
      权益三:体验AI留声机，三步完成AI语音合成，完美复制个人音色，一键文字转音频。订购产品即可免费使用AI留声功能。</br>
      <span class="colorTitle">4、权益使用路径:</span></br>
      热门会员及5元话费兑换方式: 关注微信公众号“沃要省钱”，点击底部菜单栏“运营商”—“运营商权益领取”，领取用户喜爱的月会员权益和5元话费，领取成功后前往各APP开启会员特权之路。话费领取成功后请关注充值到账短信。 AI留声机使用方式：微信搜索“联通爱听”微信公众号，点击菜单栏“爱听”-“免费录音”，开始体验。</br>
      <span class="colorTitle">5、包月资源使用路径：</span> 下载并登录联通爱听APP-点击“我的”-“已购买”-选择对应产品-使用；或关注“联通爱听”微信公众号，点击菜单栏“爱听”-“书城”-“我的”-“包月”列表-选择所订购产品-使用。</br>
      <span class="colorTitle">6、注意事项:</span></br>
      <span class="colorTitle">(1) 需用户主动去相应平台兑换热门会员和相应面值的话费。每月下发的权益兑换券30天有效，如未按时领取导致过期失效。</br>
      (2) 本业务当月退订后，从次月起不再享有课程观看权限和领取热门权益，已产生的业务订购费用不予退还。</span></br>
      <span class="colorTitle">7、订购查询:</span>本活动业务为自动续费产品，用户订购期内不退订，下月将进行自动扣费续订。订购关系查询路径：下载并登陆联通爱听APP-我的-已购买-包月，即可查询。</br>
      <span class="colorTitle">8、退订方式:</span>关注“联通爱听”微信公众号-点击菜单栏“我的”-“我的已购”-退订；或拨打电话4006022088进行退订；</br>
      9、如有任何问题，请咨询客服热线: <span class="colorTitle">4006022088</span>，客服时间: 周一至周五 8:30-12:00、14:00-18:00。周末及节日正常休息，望谅解。</br>
      10、此产品归属联通沃悦读科技文化有限公司。</br>
  `,
  yinsi1: `
        《关于客户个人信息收集、使用规则的公告》<br />
        尊敬的客户：<br />
        <p>根据《中华人民共和国反恐怖主义法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》
        （工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，
        客户在中国联合网络通信有限公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等）办理固定电话、
        移动电话（含无线上网卡）入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，
        登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。
        客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。</p>
        <br />
        如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。<br />
        为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、
        必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息：<br />
        (a)事先获得客户的明确授权；<br />
        (b)根据有关的法律法规要求；<br />
        (c)按照相关司法机关和/或政府主管部门的要求；<br />
        (d)为维护社会公众的利益所必需且适当；<br />
        (e)为维护我公司或客户的合法权益所必需且适当。<br />
    `,
  yinsi2: `
            联通沃悦读科技文化有限公司<br /><br /><br />
            首次发布日期：2019年1月1日<br />
            更新日期：2021年12月2日<br />
            生效日期：2021年12月9日<br />
            联通沃悦读科技文化有限公司（下文简称为“我们”）非常重视用户的隐私和个人信息保护，致力于维持您对我们的信任。我们承诺采取相应的安全保护措施来保护您的个人信息。本政策适用于联通沃悦读科技文化有限公司基于沃阅读产品所提供的服务，请您在使用沃阅读产品服务前，仔细阅读并了解本《隐私政策》。
            本隐私政策与您所使用的我们的产品或服务密切相关，对于您行使个人权利及保护您的个人信息至关重要，请您在使用我们的产品或服务前务必认真阅读并充分理解本政策所写明的内容。<br />
            阅读过程中，如您对我们的隐私政策及服务条款的内容有任何疑问，请联系我们。一旦您开始使用我们的产品或服务，即表示您已经理解并同意本隐私政策全部内容。若本隐私政策有内容更新，我们会及时提示您更新的情况。<br />
            第一部分：定义与名词解释<br />
            沃阅读：指我们提供的数字阅读产品、服务、品牌，服务网站为m.woread.com.cn，服务软件为一款名为“沃阅读”的APP。沃阅读品牌旗下还有独立子品牌产品：沃阅视界、萌娃小Q、联通爱听<br />
            个人信息：以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、帐号密码、财产信息、征信信息、行踪轨迹、交易信息等。<br />
            个人敏感信息：一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14岁以下（含）儿童的个人信息等。<br />
            用户画像：通过收集、汇聚、分析个人信息，对某特定自然人个人特征，如其职业、经济、健康、教育、个人喜好、信用、行为等方面做出分析或预测，形成其个人特征模型的过程。直接使用特定自然人的个人信息，形成该自然人的特征模型，称为直接用户画像。使用来源于特定自然人以外的个人信息，如其所在群体的数据，形成该自然人的特征模型，称为间接用户画像。<br />
            唯一设备标识符：是指由设备制造商编入到设备中的一串字符，可用于以独有方式标识相应设备。具体包含有：国际移动设备识别码(IMEI)、匿名设备标识符(OAID)、Android ID、iOS广告标识符(IDFA)、网卡(MAC)地址、国际移动用户识别码(IMSI)、OpenUDID、GUID。<br />
            去标识化：通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经去标识化处理后所得的信息不属于个人信息。<br />
            第二部分：隐私政策<br />
            本隐私政策包含以下内容：<br />
            一、我们如何收集和使用您的个人信息；<br />
            二、我们如何使用Cookie和同类技术；<br />
            三、我们如何共享、转让、公开披露您的个人信息；<br />
            四、我们如何保护您的个人信息；<br />
            五、我们如何处理未成年人的个人信息；<br />
            六、您的个人信息如何在全球范围转移；<br />
            七、本政策如何更新；<br />
            八、您的权利；<br />
            九、如何联系我们。 <br />
            一、我们如何收集和使用个人信息<br />
            （一）收集目的和范围<br />
            为更好地为您提供服务，沃阅读将遵循“合法、正当、必要”原则，按照法律法规的规定和您的同意收集您的个人信息，主要包括：<br />
            1、保障“沃阅读”软件正常<br />
            为保障“沃阅读”正常运行，当您使用“沃阅读”软件及相关服务时，我们会收集您的硬件型号、操作系统版本号、国际移动设备识别码（IMEI）、SIM卡唯一标识（IMSI）、Android
            ID、IDFA、IP地址、网卡(MAC)地址、应用列表信息、蓝牙信息、WIFI状态信息、软件版本号、网络接入方式及类型、操作日志信息。<br />
            为了实现在线和离线阅读、听书、观影功能，我们会向您请求访问设备存储权限，用于文本书籍、音频文件、视频文件缓存/下载时的存储和读取。<br />
            为了实现在线阅读功能，我们会向您请求网络权限，用于向沃阅读服务器请求数据资源。<br />
            以上信息是我们通过“沃阅读”向您提供服务所必须收集的基本信息和基本权限。<br />
            2、注册、认证、登录“沃阅读”和相关服务<br />
            （1）当您注册、登录“沃阅读”及相关服务时，如果您是中国联通用户，您可以使用 “联通用户一键登录”功能，通过授权我们收集、验证您的手机号码，便于我们为您提供快捷的登录服务。如您不选择“联通用户一键登录”，您还可以使用其他登录方式，如填写手机验证码登录、密码登录、第三方账号登录。当您使用“手机验证码登录”功能时，我们会请求您授权短信相关权限，用于短信下行验证码自动填充。您如果拒绝授权提供，将无法使用验证码自动填充功能，但不影响你正常使用“沃阅读”的其他功能。<br />
            （2）如果您选择通过手机号创建账号，我们将通过发送短信验证码、上行短信多种方式（任选其一）来验证您的身份是否有效，并且您可完善相关的网络身份识别信息（如头像、昵称和密码），收集这些信息是为了帮助您完成注册。您还可根据自身需求选择填写签名、性别、生日、地址、阅读偏好相关信息完善您的个人信息。当您使用“头像”功能时，我们会请求您授权相机，用于拍照、读取相册照片并发布。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用“沃阅读”的其他功能。<br />
            （3）您也可以使用第三方账号（如微信、QQ、微博账号、Appleld苹果账号）登录进入“沃阅读”，您此时将授权我们获取您在第三方平台注册的公开信息（头像、昵称），并在您同意本隐私政策后将您的第三方账号与您的沃阅读手机账号绑定，使您可以通过第三方账号直接登录并使用本产品和相关服务。<br />
            （4）未登录沃阅读并不会影响您使用沃阅读搜索浏览其他功能，但可能会影响您购买图书、发表评论附加业务功能。<br />
            3、提供赠书、护眼模式功能和服务<br />
            （1）当您使用赠书功能时，我们会请求您授权手机通讯录权限，用于选择通讯录好友并完成书籍赠送流程。您如果拒绝授权提供，将无法使用此功能，但不影响你正常使用“沃阅读”的其他功能。<br />
            （2）当您使用护眼模式功能时，我们会请求您授权悬浮窗权限，用于过滤蓝光以保护您的视力。您如果拒绝授权提供，将无法使用此功能，但不影响你正常使用“沃阅读”的其他功能。<br />
            （3）当您使用留声机相关功能时，我们会请求您授权麦克风权限，用于声音的录制。您如果拒绝授权提供，将无法使用此功能，但不影响你正常使用“沃阅读”的其他功能。<br />
            （4）当您使用部分基于地理位置的个性化内容推荐服务时，我们会请求您的地理位置信息权限。如果您拒绝提供地理位置信息，我们将不会基于地理位置信息向您推送内容，但不影响“沃阅读”其他功能与服务的正常使用。<br />
            另外，我们还提供基于用户访问行为的个人内容推荐服务，通过采集用户的页面访问、搜索、内容使用以及使用时长信息，以及发布、评论、笔记、反馈等主动提供的信息。结合我们平台自有其他服务信息，通过算法分析向您提供包括“猜你喜欢”、“大家都在看”、“同类推荐”等栏目提供个性化内容与服务推荐和展示。如您不同意提供上述服务，您可以通过点击应用“我的->设置->阅读设置”路径对“个性推荐功能”进行关闭，但不影响您正常使用“沃阅读”的其他功能。<br />
            （5）当您选择参加我们举办的有关营销活动时，根据活动需要您可提供姓名、通信地址、联系方式及相关信息。这些信息中可能包含个人敏感信息（如个人电话号码、通信地址），是您收到营销活动所涉及的奖励所必要的，如果您拒绝提供这些信息，我们将可能无法向您发放相关奖励。<br />
            （6）当您使用部分展示广告信息的服务时，为提高用户的广告体验，我们会收集您的应用安装列表信息，如果您拒绝提供这些信息，可能会向您投放错误的广告。<br />
            （7）如果您不想接收我们为您发送的营销短信可以通过“设置-短信通知” 路径选择关闭，关闭后不会影响您使用“沃阅读”的其他功能。<br />
            您可以点击查阅《个人信息收集清单》了解详情。<br />
            您可以点击查阅《应用权限申请与使用情况说明》了解详情<br />
            4、用户行为日志信息<br />
            您使用我们服务时，系统可能通过cookies、web beacon或其他方式自动采集的技术信息，除为确保软件正常运行所需的基本信息外，还包括：<br />
            （1）您通过沃阅读订购包月业务、电子书、漫画、视频以及阅读周边衍生品虚拟或者实体商品时产生的记录，如订购记录、消费记录。<br />
            （2）有关您使用沃阅读的操作日志，如搜索关键字、使用时长、阅读记录及其他数据；<br />
            （3）您在使用沃阅读的特定服务时，为了启用该特定服务，必须收集的信息，如您在使用评论、笔记、留声机及其他功能时，您的这些相关信息会存储于我们的服务器中。<br />
            （4）我们会基于前述信息以及您的收藏数据（如您收藏的图书、音视频）、购买数据对您的偏好、阅读习惯特征分析和用户画像，以便为您提供更适合您的定制化服务，如向您展现或推荐相关程度更高的搜索结果。 <br />
            （5）在采集过程中，我们将严格遵守相关法律法规及监管政策，不采集任何法律法规及监管政策禁止采集的信息。您提供的上述信息，将在您使用本沃阅读产品服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息或做匿名化处理。在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理。去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在符合相关法律法规的前提下，我们有权对包括您的个人信息在内的用户数据库进行整体化分析和利用（包括商业性利用）。<br />
            （6）为共同向您提供产品服务，改进产品服务的质量或出于对产品服务安全性的考量等合理需要，我们可按照相关法律法规及监管政策的要求或经过您的授权从关联公司、合作伙伴及其他受信任的第三方接收您的个人信息及其他信息。如当您通过我们关联公司、业务合作伙伴网站订购我们的产品，您向其提供的预订信息可能会转交给我们，以便我们处理您的订单。<br />
            （7）您了解并同意，以下情形中我们使用个人信息无需征得您的授权同意:<br />
            1) 与国家安全、国防安全有关的；与公共安全、公共卫生、重大公共利益有关的；<br />
            2) 与犯罪侦查、起诉、审判和判决执行等有关的；<br />
            3) 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难征得本人同意的；<br />
            4) 所收集的个人信息是个人信息主体自行向社会公众公开的；<br />
            5) 从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；<br />
            6) 根据您的要求签订和履行合同所必需的；用于维护所提供的产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；<br />
            7) 法律法规及监管政策规定的其他情形。<br />
            5、 第三方SDK收集使用信息说明<br />
            为保障沃阅读的稳定运行或实现功能，我们可能会接入由第三方提供的软件开发包(SDK)实现前述目的。我们会尽到审慎义务，对合作方获取信息的软件工具开发包(SDK)进行严格的安全监测，以保护数据安全。我们接入的第三方
            SDK主要服务于您以及其他的用户的需求，因此在满足新的服务需求及业务功能变更时，我们可能会调整我们接入的第三方SDK。我们会及时在本说明中向您公开说明接入第三方 SDK的最新情况。<br />
            目前沃阅读安卓平台接入的第三方SDK列明如下：<br />
            （1）授权登录SDK<br />
            联通在线信息科技有限公司用户中心SDK、QQ登录SDK、微信登录SDK、新浪微博登录SDK。这些SDK需要获取您的国际移动设备识别码（IMEI）、安卓系统封装用户ID，并可能会收集您的手机号码、QQ号码（QQ登录SDK）、微信号码（微信登录SDK）、新浪微博账号（新浪微博登录SDK），用于实现产品登录功能。<br />
            （2）推送SDK：<br />
            友盟推送SDK，小米、魅族、华为、VIVO、OPPO品牌手机厂商的APP推送SDK、个推SDK。这些SDK需要获取您的国际移动设备识别码（IMEI）、安卓系统封装用户ID，并可能会收集您的设备型号、设备MAC地址、网络状态信息、应用使用信息、操作系统和应用程序版本、设备屏幕尺寸信息，用于实现APP推送服务。<br />
            （3）分享SDK<br />
            微博分享SDK、微信分享SDK、QQ分享SDK。这些SDK可能会收集您的国际移动设备识别码（IMEI）、安卓系统封装用户ID、设备MAC地址、登录IP地址、设备型号、操作系统和应用程序版本；为了调起您设备中的社交平台APP（微博、微信、QQ）以实现社交分享，还会收集您系统在装应用列表信息。<br />
            （4）支付SDK<br />
            中国联通集团CAP支付SDK、支付宝支付SDK、微信支付SDK，可能需要获取您的国际移动设备识别码（IMEI）、安卓系统封装用户ID、设备型号、屏幕尺寸、系统版本、系统类型、终端存储信息，用于实现在线支付功能；可能需要获取位置信息，提供反欺诈功能，保障支付安全。<br />
            （5）智能语音SDK<br />
            为了向您提供语音搜索、语音朗读及AI语音助手服务，我们使用了讯飞SDK，可能需要收集您的国际移动设备识别码（IMEI）、安卓系统封装用户ID、设备MAC地址、网络信息，可能需要您的存取权限和麦克风权限，以提供智能语音理解及语音指令响应。<br />
            （6）文本翻译SDK<br />
            为了向您提供生词解释功能，我们使用了有道词典翻译SDK，可能需要收集您的国际移动设备识别码（IMEI）、安卓系统封装用户ID、可能需要您的存取权限。<br />
            （7）异常统计SDK<br />
            为了向您提供更好的功能体验、改进产品服务，我们使用了腾讯Bugly
            SDK、百度统计SDK、友盟统计SDK、MSA移动安全联盟SDK，可能需要收集您设备的国际移动设备识别码（IMEI）、安卓系统封装用户ID、设备Mac地址、设备品牌、设备型号、系统类型、系统版本、设备屏幕尺寸信息，可能需要您的存取权限，以记录服务中的异常情况，帮助产品定位并修复问题，提升服务体验。<br />
            （8）音视频播放SDK<br />
            ijkplayer视频SDK播放进行联网服务，需要获取您的网络权限。<br />
            （9）二维码SDK<br />
            Zxing二维码SDK，为您提供二维码分享、扫描功能，我们在您授权同意后获取您的摄像头权限或拍照权限。如您不同意授权，该功能将不能使用。<br />
            （10）OkHttp安卓网络框架SDK<br />
            OkHttp安卓网络框架SDK(okio)，提升网络使用效率，我们使用了在使用期间可能会获取您的网络权限。<br />
            （11）投屏SDK<br />
            乐播投屏SDK，用于视频投屏功能，可能需要收集您的设备国际移动设备识别码（IMEI）、安卓系统封装用户ID、设备型号、设备分辨率、系统版本、系统类型、设备所在位置相关信息（例如IP地址、蓝牙信息），以确保功能正常运行，提升服务体验。<br />
            （12）移动智能终端补充设备标识体系统一调用SDK<br />
            由中国信息通信研究院泰尔终端实验室、移动安全联盟共同推出的SDK，协助访问移动智能终端补充设备标识体系，推进相关业务。移动智能终端补充设备标识体系依据电信终端产业协会（TAF）、移动安全联盟（MSA）联合推出的团体标准《移动智能终端补充设备标识规范》开发，该SDK集成设备厂商提供的接口，并已获得相应设备厂商的授权。<br />
            （13）图片显示SDK<br />
            Fresco图片显示SDK、Glide图片显示SDK，提升图片显示效率，使用过程可能会获取您的网络权限、可能需要您的存取权限。<br />
            （14）VR播放SDK<br />
            MD360Player4Android VR播放SDK，用于VR播放，使用过程可能会获取您的网络权限。<br />
            （15）文件浏览服务SDK<br />
            腾讯文件浏览服务TBS SDK，展示word、excel、pdf等文档，使用过程可能会获取您的网络数据、可能需要您的存取权限。<br />
            （16）数据库SDK<br /><br />
            GreenDao数据库SDK、SQLCipher数据库SDK，用于更好、更快、更安全的存储数据，使用过程可能需要您的存取权限。<br />
            （17）Android版本兼容SDK<br />
            AndroidX，用于兼容Android不同版本，提供更多新版系统组件、用户界面交互及数据访问帮助。<br />
            （18）广告SDK<br />
            为了向您提供广告服务，我们使用了穿山甲广告SDK，可能需要收集您设备品牌、设备型号、系统类型、系统版本、您的国际移动设备识别码、设备MAC地址、设备屏幕尺寸信息。<br />
            您可以点击查阅《第三方信息共享数据清单》了解详情。<br />
            目前沃阅读iOS平台接入的第三方SDK列明如下：<br />
            （1）授权登录SDK<br />
            联通在线信息科技有限公司用户中心SDK、QQ登录SDK、微信登录SDK、新浪微博登录SDK。这些SDK需要获取您的终端设备唯一设备标识符、，并可能会收集您的手机号码、QQ号码（QQ登录SDK）、微信号码（微信登录SDK）、新浪微博账号（新浪微博登录SDK），用于实现产品登录功能。<br />
            （2）分享SDK<br />
            微博分享SDK、微信分享SDK、QQ分享SDK。这些SDK可能会收集您的终端<br />
            设备唯一设备标识符、设备MAC地址、登录IP地址、设备型号、设备标识、操作系统和应用程序版本；为了调起您设备中的社交平台APP（微博、微信、QQ）以实现社交分享，还会收集您系统在装应用列表信息。<br />
            （3）支付SDK<br />
            中国联通集团CAP支付SDK、支付宝支付SDK、微信支付SDK，可能需要获取您的终端设备唯一设备标识符、设备型号、屏幕尺寸、系统版本、系统类型、手机存储信息，用于实现在线支付功能；可能需要获取位置信息，提供反欺诈功能，保障支付安全。<br />
            （4）智能语音SDK<br />
            为了向您提供语音搜索、语音朗读及AI语音助手服务，我们使用了讯飞SDK，可能需要收集您的终端设备唯一设备标识符、设备MAC地址、网络信息，可能需要您的存取权限和麦克风权限，以提供智能语音理解及语音指令响应。<br />
            （5）文本翻译SDK<br />
            为了向您提供生词解释功能，我们使用了有道词典翻译SDK，使用了 AdSupport框架获取设备 IDFA 信息用以统计计费。<br />
            （6）异常统计SDK<br />
            为了向您提供更好的功能体验、改进产品服务，我们使用了友盟SDK，可能需要收集您的设备品牌、设备型号、系统类型、系统版本、设备屏幕尺寸信息、iOS广告标识符（IDFA)信息，以记录服务中的异常情况，帮助沃阅读产品定位并修复问题，提升服务体验。<br />
            （7）音视频播放SDK<br />
            ijkplayer视频SDK播放进行联网服务，需要获取您的网络权限。<br />
            （8）投屏SDK<br />
            乐播投屏SDK，用于视频投屏功能，可能需要收集您的设备型号、系统版本、局域网接入设备信息，以确保功能正常运行，提升服务体验。<br />
            （9）VR播放SDK<br />
            使用了Google GVR SDK，用于播放VR视频，使用过程可能会获取您的网络权限。<br />
            （10）广告SDK<br />
            为了向您提供广告服务，我们使用了穿山甲广告SDK，可能需要收集您的终端设备唯一设备标识符、设备品牌、设备型号、系统类型、系统版本、iOS广告标识符（IDFA)、设备屏幕尺寸信息。<br />
            6、提供沃阅读更多产品和服务<br />
            为改善我们的产品或服务以及能更方便为您提供沃阅读更多产品和服务，我们会根据您的浏览及搜索记录、设备信息、订单信息等提取您的浏览、搜索偏好、行为习惯等特征，基于特征标签进行间接人群画像以优化我们的服务，提升您的产品体验。您将有可能通过信息推送、电话、广告、电子邮件或者其他社交账号共享等方式接收到由我们的产品信息和服务推荐的信息。<br />
            在接收推荐的同时，您可以进行以下操作：<br />
            （1）了解和使用沃阅读其他相关产品；<br />
            （2）购买沃阅读产品或者服务，该产品包括沃阅读以及沃阅读与第三方合作伙伴合作的产品或者服务；<br />
            （3）参与沃阅读调研和其他活动；<br />
            如果您不希望接收我们给您发送的相关改善和服务通知，您可按照相关提示随时取消。<br />
            （二）如何使用<br />
            为向您提供服务及改善服务质量，在遵守国家法律法规及监管政策的前提下，我们可能将收集到的您的个人信息用于以下目的：<br />
            1、为您提供服务<br />
            （1）在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；<br />
            （2）通过信息数据分析，帮助我们设计为您提供更好感知的新服务，改善我们现有服务；<br />
            （3）根据国家相关要求，用于旨在推进政府管理、加强社会治理、改善社会民生、建立诚信体系等目的的服务；<br />
            （4）使我们更加了解您如何使用我们的服务，从而针对性地满足您的个性化需求，如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他使用我们服务的用户做出其他方面的回应；<br />
            （5）用于向您提供个性化或定制化产品和服务，如向您展现或推荐相关程度更高信息流或者推广信息结果。如您不希望收到这些信息，可以按照我们的相关提示取消订阅；<br />
            （6）评估我们服务中的广告和其他促销及推广活动的效果，并加以改善；<br />
            （7）软件认证或管理软件升级；<br />
            （8）邀请您参与有关我们产品和服务的调查。<br />
            2、您了解并同意，一旦您开始使用我们的产品，等同于您已同意我们的隐私政策。我们将通过技术手段对您的个人信息数据进行去标识化处理，去标识化处理的信息将无法识别个人主体。在此情况下我们有权使用已经去标识化的信息，并在符合相关法律法规及监管政策的前提下，我们有权对包括您的个人信息在内的用户数据库进行整体化分析和利用。<br />
            3、当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。<br />
            二、我们如何使用 Cookie 和同类技术<br />
            1、Cookie<br />
            为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的账号信息、阅读记录数据，用于保持用户的身份识别、连续阅读体验。<br />
            我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。<br />
            2、网站信标和像素标签<br />
            除Cookie外，我们还有可能会在网站上使用网站信标和像素标签等其他同类技术。比如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击
            URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。<br />
            3、Do Not Track（请勿追踪）<br />
            很多网络浏览器均设有“Do Not Track”（请勿追踪）功能，该功能可向网站发布“Do Not Track”请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了“Do
            Not Track”，那么我们的所有网站都会尊重您的选择。<br />
            4、第三方分析软件<br />
            我们可能使用友盟、百度第三方分析软件来开发我们的服务和分析您使用服务的情况。友盟、百度第三方分析软件将根据其隐私政策使用、处理通过我们服务搜集到的您的数据。您在安装、使用、注册或以其他方式访问我们的服务前，请仔细阅读友盟、百度公司的隐私政策。<br />
            三、我们如何共享、转让和公开披露个人信息<br />
            1、共享<br />
            我们不会与联通沃悦读科技文化有限公司以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：<br />
            1）在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。<br />
            2）我们可能会根据法律法规及监管政策要求，或按政府主管部门的强制性要求，对外共享您的个人信息。<br />
            3）与我们的关联公司共享：您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。<br />
            我们的关联公司包括联通沃悦读科技文化有限公司母公司中国联合网络通信有限公司、联通在线信息科技有限公司。<br />
            4）去标识化处理后的信息与第三方共享，共享第三方无法重新识别此类信息的自然人主体；<br />
            5）与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。比如，在您通过我们的网站购买商品或订购业务时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。<br />
            目前，我们的授权合作伙伴包括以下三大类型：<br />
            A：供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。<br />
            B：第三方商家。我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。<br />
            C：广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，比如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴分享。但我们可能会将使用您的信息而形成的用户画像与提供广告、分析服务的合作伙伴共享，以帮助在不识别您个人的前提下提升广告及服务信息的有效触达率。<br />
            对我们与之共享个人信息的公司、组织和个人，我们会与其签署相应的保密协定，要求他们按照我们的说明、隐私政策以及其他任何相关的保密和安全措施来处理个人信息。<br />
            2、转让<br />
            涉及合并、收购、重组或破产清算时，如涉及个人信息转移，我们将向您告知接收方的名称和联系方式，并要求新的接收方继续受本隐私政策的约束。如接收方变更个人信息的处理目的和处理方式，我们将要求该接收方重新获得您的明确同意。<br />
            3、公开披露<br />
            我们仅会在以下情况下，公开披露您的个人信息：<br />
            1）获得您明确同意后；<br />
            2）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们会公开披露您的个人信息。<br />
            四、我们如何保护您的个人信息<br />
            1、保存期限<br />
            我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非经过您的同意延长保留期或受到法律的允许。如果您注销账号或主动删除上述信息，我们将按照法律、法规规定的最短期限保留您的现有个人信息，在法律法规要求的最短保存期限内，我们将不再对您的个人信息进行商业化使用。当您的个人信息超出上述保存期限（用户主动销户后一年内），我们将会对其进行匿名化处理。<br />
            2、存放地域<br />
            您的个人信息均储存于中华人民共和国境内；如需跨境传输，我们将会单独征得您的授权同意。<br />
            3、安全保护措施<br />
            1）我们会以“最小化”原则收集、使用、存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。我们会严格按照《网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）、《电话用户真实身份信息登记规定》（工业和信息化部令第25号）、《数据安全法》、《个人信息保护法》等法律法规的相关要求，建立信息安全保障制度，采取技术措施和其他必要措施保护您的个人信息安全。<br />
            2）为保障您的信息安全，我们将运用各种安全技术和程序建立完善的管理制度来保护你的个人信息，以免遭受未经授权的访问、使用或披露。如：使用加密技术确保数据的保密性；使用受信赖的保护机制防止数据遭到恶意攻击；对相关访问进行授权；定期开展数据安全专项检查等安全防护与检测措施；对员工进行数据安全的意识培养和安全能力的培训，加强员工对于保护个人信息重要性的认识。我们每年会接受相关国家机构的信息安全检查。<br />
            3）您须承诺妥善保存登录的账户及密码信息，由于您的原因造成账户密码的泄露产生的后果和法律责任由您承担。为防止信息泄露，我们将采取防泄漏、反爬虫等技术手段，限制频繁查询您的信息等异常操作行为，以防止账号密码泄露或他人冒用身份造成您产生不良行为记录或者您的信息泄露。<br />
            4）请您理解：互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性，但由于技术的限制及可能存在的各种恶意手段，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百安全。<br />
            5）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。<br />
            五、我们如何处理未成年人的个人信息<br />
            1、我们非常重视对未成年人个人信息的保护。我们将根据国家相关法律法规及监管政策的规定保护未成年人的个人信息。<br />
            2、对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。<br />
            3、如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，会设法尽快删除相关数据。<br />
            六、您的个人信息如何在全球范围转移<br />
            我们在中华人民共和国境内收集的个人信息，将存储在中华人民共和国境内。我们仅在本隐私政策所述目的、所必需期间和法律法规及监管政策规定的时限内保留您的个人信息。如果需要将您的个人信息提供到境外，我们将：<br />
            1.另行获得您的授权同意并履行法定程序，在符合届时适用法律的情形下使您的个人信息得到足够的保护；<br />
            2.该等转移将在符合届时适用法律要求的前提下进行，即便获得您的授权，但如果转移方式或者目的不符合相关法律法规规定的，我们也不会进行转移。<br />
            七、本政策如何更新<br />
            我们的隐私政策可能变更。未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。为给您提供更好的服务以及随着电信业务的发展或法律法规及监管政策变化，我们可能会适时对本政策进行更新。<br />
            由于我们的用户较多，如本政策发生重大变更，我们将以推送通知、发送邮件、信函、电话或者在沃阅读APP弹窗通知、沃阅读官方微信公众号发布公告的方式来通知您。若您在本政策修订后继续使用我们的产品或服务，这表示您已充分阅读、理解并愿意受修订后的新政策约束。<br />
            本隐私政策所指的重大变化包括但不限于：<br />
            1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br />
            2.我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；<br />
            3.收集、使用个人信息的范围、目的、规则发生变化；<br />
            4.对外提供个人信息的对象、范围、目的发生变化；<br />
            5.您参与个人信息处理方面的权利及其行使方式发生重大变化；<br />
            6.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；<br />
            7.数据安全能力、信息安全风险发生变化。<br />
            如无特殊说明，修订后的隐私政策自公布之日起生效。<br />
            八、您的权利<br />
            1、查询和更正您的个人信息<br />
            您可以随时登录个人账户，访问或编辑账户中的个人资料信息、更改密码等。您可以在沃阅读产品的服务中查阅个人的浏览记录、搜索记录、上传内容、订单信息等。
            在您通过用户身份验证，且更正不影响信息的客观性和准确性的情况下，您有权对错误或不完整的信息作出更正或更新。您可以自行在沃阅读产品中进行更正，或通过意见反馈或联系客服等将更正申请提交给我们。<br />
            如您需要您的个人信息副本，您可以通过本政策中第九章节中提供的联系方式联系我们，在核实您的身份后，我们将向您提供您在我们服务中的个人信息副本，但法律法规另有规定的或本政策另有约定的除外。<br />
            2、删除个人信息<br />
            您在我们的产品与/或服务页面中可以直接清除或删除的信息，包括浏览信息、订购信息、收藏信息等；<br />
            在以下情形中，您可以向我们提出删除个人信息的请求（本政策中第九章节中提供的联系方式联系我们）：<br />
            （1）如果我们处理个人信息的行为违反法律法规；<br />
            （2）如果我们收集、使用您的个人信息，却未征得您的同意；<br />
            （3）如果我们处理个人信息的行为违反了与您的约定；<br />
            （4）如果您不再使用我们的产品或服务，或您注销了账号；<br />
            （5）如果我们不再为您提供产品或服务。<br />
            当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。<br />
            3、撤回授权同意<br />
            每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意（App内操作路径：我的-设置-消息通知-接收系统消息-立即前往）。<br />
            (1) 您可以通过删除信息、关闭设备功能、更改设备应用授权设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。<br />
            (2)当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。<br />
            4、注销账号<br />
            您注销账号之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。具体注销账号的方式，通过点击应用“我的-设置-账号管理”路径选择注销账号或者拨打客服热线（400-602-2088）进行人工注销。<br />
            5.响应您的上述请求<br />
            为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的要求。对您关于用户个人信息的合理请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。在以下情形中，按照法律法规及监管政策要求，我们将无法响应您的请求：<br />
            （1）与国家安全、国防安全直接相关的；<br />
            （2）与公共安全、公共卫生、重大公共利益直接相关的；<br />
            （3）与犯罪侦查、起诉、审判和判决执行等直接相关的；<br />
            （4）有充分证据表明您存在主观恶意或滥用权利的；<br />
            （5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；<br />
            （6）涉及商业秘密的。<br />
            九、如何联系我们<br />
            公司注册地址：湖南省长沙县黄花镇长沙临空经济示范区人民东路与小康路交汇处西南角房屋<br />
            客服邮箱地址：ireadkefu@wo.cn<br />
            如果您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，您通过沃阅读网站(m.woread.com.cn)、APP的意见反馈或者拨打客服热线（400-602-2088）与我们联系，我们会积极进行查证、处理并尽快答复。<br />
            我们设立了个人信息保护专员，您可以通过以下方式与其取得联系：ireadkefu@wo.cn.我们将尽快审核所涉问题，并在验证您的真实身份后在十五天内回复。如果您对于我们的回复不满意，您可以向我们提出相关建议。<br />
    `,
  yinsi3: `
    摘星社信息科技(浙江)股份有限公司尊重并保护所有使用摘星社信息科技(浙江)股份有限公司网络服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，摘星社信息科技(浙江)股份有限公司会按照本隐私权政策的规定使用和披露您的个人信息。但摘星社信息科技(浙江)股份有限公司将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，摘星社信息科技(浙江)股份有限公司不会将这些信息对外披露或向第三方提供。摘星社信息科技(浙江)股份有限公司会不时更新本隐私权政策。
						您在同意摘星社信息科技(浙江)股份有限公司络服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于摘星社信息科技(浙江)股份有限公司络服务使用协议不可分割的一部分。<br>
						<br>
						1.适用范围<br>
						<br>
						a) 在您注册摘星社信息科技(浙江)股份有限公司帐号时，您根据摘星社信息科技(浙江)股份有限公司要求提供的个人注册信息（商家应法律法规要求需公示的企业名称及相关工商注册信息除外）；<br>
						b)
						在您使用摘星社信息科技(浙江)股份有限公司网络服务，或访问摘星社信息科技(浙江)股份有限公司平台网页时，摘星社信息科技(浙江)股份有限公司自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；<br>
						c) 摘星社信息科技(浙江)股份有限公司通过合法途径从商业伙伴处取得的用户个人数据。<br>
						您了解并同意，以下信息不适用本隐私权政策：<br>
						<br>
						a) 您在使用摘星社信息科技(浙江)股份有限公司平台提供的搜索服务时输入的关键字信息；<br>
						b) 摘星社信息科技(浙江)股份有限公司收集到的您在摘星社信息科技(浙江)股份有限公司发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；<br>
						c) 违反法律规定或违反摘星社信息科技(浙江)股份有限公司规则行为及摘星社信息科技(浙江)股份有限公司已对您采取的措施。<br>
						<br>
						2.信息的使用<br>
						<br>
						a)
						摘星社信息科技(浙江)股份有限公司不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和摘星社信息科技(浙江)股份有限公司（含摘星社信息科技(浙江)股份有限公司关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。<br>
						b)
						摘星社信息科技(浙江)股份有限公司亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何摘星社信息科技(浙江)股份有限公司平台用户如从事上述活动，一经发现，摘星社信息科技(浙江)股份有限公司有权立即终止与该用户的服务协议。<br>
						c)
						为服务用户的目的，摘星社信息科技(浙江)股份有限公司可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与摘星社信息科技(浙江)股份有限公司合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。<br>
						<br>
						3.信息披露<br>
						<br>
						在如下情况下，摘星社信息科技(浙江)股份有限公司将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：<br>
						<br>
						a) 经您事先同意，向第三方披露；<br>
						b) 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；<br>
						c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；<br>
						d) 如您出现违反中国有关法律、法规或者摘星社信息科技(浙江)股份有限公司服务协议或相关规则的情况，需要向第三方披露；<br>
						e) 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；<br>
						f)
						在摘星社信息科技(浙江)股份有限公司平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，摘星社信息科技(浙江)股份有限公司有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。<br>
						g) 其它摘星社信息科技(浙江)股份有限公司根据法律、法规或者网站政策认为合适的披露。<br>
						<br>
						4. 信息存储和交换<br>
						<br>
						摘星社信息科技(浙江)股份有限公司收集的有关您的信息和资料将保存在摘星社信息科技(浙江)股份有限公司及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或摘星社信息科技(浙江)股份有限公司收集信息和资料所在地的境外并在境外被访问、存储和展示。<br>
						<br>
						5. Cookie的使用<br>
						<br>
						a)
						在您未拒绝接受cookies的情况下，摘星社信息科技(浙江)股份有限公司会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的摘星社信息科技(浙江)股份有限公司平台服务或功能。摘星社信息科技(浙江)股份有限公司使用cookies可为您提供更加周到的个性化服务，包括推广服务。<br>
						b)
						您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的摘星社信息科技(浙江)股份有限公司网络服务或功能。<br>
						c) 通过摘星社信息科技(浙江)股份有限公司所设cookies所取得的有关信息，将适用本政策。<br>
						<br>
						6. 信息安全<br>
						<br>
						a)
						摘星社信息科技(浙江)股份有限公司帐号均有安全保护功能，请妥善保管您的用户名及密码信息。摘星社信息科技(浙江)股份有限公司将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。<br>
						b)
						在使用摘星社信息科技(浙江)股份有限公司网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是摘星社信息科技(浙江)股份有限公司用户名及密码发生泄露，请您立即联络摘星社信息科技(浙江)股份有限公司客服，以便摘星社信息科技(浙江)股份有限公司采取相应措施。<br>
    `,
  //微信连续包月协议
  yinsi4: `
    1、服务条款的确认和接纳<br>
    为满足您对全民家会员产品及连续包扣款的需求，全民家会员推出连续包服务，相关服务请关注本协议约定。若需采购，请点击 “连续包月 ”选项采购该产品，本协议即构成您使用全民家会员所提供的本连续包服务之先决条件，除非您接受本协议条款，否则您无权使用本服务。您选择使用本服务行为将视为同意接受本协议的约束。当您使用连续包服务时，您的使用行为将被视为其对该单项服务的相关条款以及全民家会员在该单项服务中所发出的各类公告之同意。<br>
    2、所有权与使用权<br>
    连续包服务涉及的一切网络服务之所有权以及相关之知识产权均归全民家会员依法所有，受中华人民共和国法律及国际公约的依法保护，您不因使用连续包服务而自动获得前述的任一或全部权利。而且，全民家会员可以在法定或依据《使用协议和版权声明》对本服务协议中的任一或全部条款进行修改，或对连续包服务进行调整，对上述修改或调整，全民家会员会在网站上予以公告，并自公告之日起7日后生效。全民家会员强烈建议您，定期关注《使用协议和版权声明》及本协议项下的条款，当您认为全民家会员对所述的《使用协议和版权声明》及本协议项下的调整不可接受时，请及时终止对全民家会员所提供之相关服务，特别是本连续包服务的使用。<br>
    3、连续包服务类型及服务内容范围<br>
    全民家会员连续包服务现仅有连续包月类型，并会根据用户需求不断增加新的连续包服务。您通过使用连续包服务，可以定期获得以下的会员服务，包括：<br>
    VIP会员服务，包括：会员购买8.8折、20元月代金券、联名月卡会员等，更详细的会员服务内容详见全民家小程序中的VIP会员特权。<br>
    除非全民家会员另外通知，否则上述的全民家会员服务的有效期按自然天数计算。连续包月用户每次续费的有效期以自然月天数计算。<br>
    4、服务说明<br>
    4.1本服务是出于您对于自动续费的需求，在您已开通本服务的前提下，避免您因疏忽或其他原因导致未能及时续费造成损失而推出的服务，您授权全民家会员可在您当期全民家会员有效期即将过期时，从会员的自有充值账户、与会员账号绑定的第三方支付账户、银行卡、通信账户（以下统称“账户”）余额中代扣下一个计费周期费用，该服务实现的前提是您已将您的全民家会员账号与上述账户绑定，且可成功从其上述账户中扣款。<br>
    4.2 自动续费具体指，基于4.1的前提，全民家会员通过上述账户收取您下一计费周期全民家会员连续包产品费用的扣费方式，您需保证全民家会员可以从上述账户扣款成功，因上述账户中可扣款余额不足导致的续费失败，由您自行承担责任。<br>
    4.3 如在扣费过程出现差错，全民家会员和您应密切配合查明原因，各自承担己方过错造成的损失；若因双方各自存在不均等过错造成的损失，由双方按过错的程度承担相应责任；双方共负责任的，由双方均摊责任。<br>
    4.4全民家会员向您提供的本服务均仅限于在全民家小程序内使用，全民家小程序外的付款或任何以恶意破解等非法手段将全民家会员提供的本服务内容与全民家小程序分离的行为，均不属于本协议中约定的本服务。由此引起的一切法律后果由行为人负责，全民家会员将依法追究行为人的法律责任。<br>
    5、连续包服务的开通<br>
    5.1 如您开通本服务，则视为您授权全民家会员有权在您的全民家VIP会员即将到期时，从您的账户余额中代扣下一个计费周期服务费，计费周期包括但不限于月度等。<br>
    5.2对于所选择的支付渠道，您有义务确保定期关注并确保其于该支付方式的账号项下有充足的余额用于满足连续包服务的应用。如因前述原因瑕疵（包括但不限于：余额不足、余额不足以满足用户选择多个连续包服务）而致使无法满足连续包服务的应用，则全民家会员有权暂停您通过连续包服务所接入的全民家VIP会员服务。<br>
    6、连续包服务有效期<br>
    除非全民家会员另行指定，否则您获得的连续包服务均为长期有效。<br>
    全民家会员将于您所选择的全民家VIP会员服务期届满前向您以发送短信或站内信或微信公众号等方式发送连续包服务续费提示，如果您不对该信息进行确认，或所回复确认之信息未尽符合全民家会员的系统要求，则全民家会员有权视您委托我司通过您默认的支付方式续取相应的服务费用。<br>
    本服务由您自主选择是否取消，若您选择不取消，则视为您同意全民家会员按照本协议进行不定期的扣款尝试，一旦扣款成功，全民家小程序将为您开通下一个计费周期的连续包服务。若在自动续费时/之前，全民家VIP会员包服务价格发生调整，应以现时有效的价格为准。<br>
    全民家会员特别提醒您：当您选择使用连续包服务后，通过连续包服务委托全民家小程序在您默认的支付方式所支付的费用，全民家会员将视您选择认购了全民家小程序的相关付费服务。因此，全民家会员将不以任何方式对您所支付的费用予以退还。<br>
    7、连续包服务的退订<br>
    7.1 您可自主选择退订本服务<br>
    7.2 解约流程，即关闭连续包会员服务步骤：<br>
    如需退订本产品：<br>
    7.2.1请登录“微信-我-服务-钱包-支付设置-自动续费”，点击“扣费服务”，下滑找到本产品扣费页面，点击“底部关闭扣费服务”，即可成功退订本产品。<br>
    7.2.2 请前往全民家小程序-我-取消续订按钮操作退订，也可退订本产品。<br>
    温馨提示：连续包套餐取消成功后，不影响您当期已生效的会员权益，会员权益到期后将不再续费。<br>
    您在选择终止本服务前已经委托全民家会员连续包扣款的指令仍然有效，全民家小程序对于基于该指令扣除的费用不予退还。<br>
    8、隐私权政策<br>
    为您能更好体验本服务，可能会收集您相应的个人信息。但全民家会员非常重视用户个人信息的保护。如您不同意请不要开通或停止使用本服务。<br>
    9、协议有效期限、终止<br>
    本协议自您选择接受或使用本服务后生效，直至您终止本服务/注销会员资格时终止。<br>
    10、争议解决和法律适用<br>
    全民家会员与您应通过友好协商解决本协议履行过程中产生的争议，经协商无法解决的，任何一方均有权将争议提交至被告方所在地人民法院诉讼解决。本协议的解释、效力和执行等均适用中华人民共和国法律。
    `,
  //全民家会员协议
  yinsi5: `
    提示：在使用全民家会员服务之前，请您先认真阅读本协议，在充分理解并同意遵守本协议各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款后再进行本平台会员服务的购买和使用。免除或者限制责任的条款，您应重点阅读。如您对本协议有任何疑问的，应向全民家小程序客服（客服电话：400-888-8202）咨询。<br>
    一、协议确认<br>
    1.本协议是您（以下称“您”或“用户”）基于对全民家的认可与全民家小程序的开发者【北京摘星格信息科技有限公司】（以下简称“摘星格”）之间就全民家平台的会员服务等相关事宜所订立的协议。<br>
    2.请您仔细阅读本会员服务协议，当您按照本协议提示填写信息、阅读并同意本协议且完成全部订立程序后，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即构成对双方有约束力的法律文件。您承诺接受并遵守本协议的约定，届时您不能以未阅读本协议的内容等理由，主张本协议无效或要求撤销本协议。<br>
    3.如果您在18周岁以下，则只能在父母或监护人的监护参与下才能使用本会员服务。若您不具备该主体资格，则您的监护人应承担因此而导致的一切后果。<br>
    二、服务内容<br>
    1.全民家平台提供的会员服务内容如下：<br>
    （1）会员期间，享有互联网热门月会员权益N选1特权，含腾讯视频、爱奇艺、优酷视频、芒果TV、QQ音乐等多款热门权益，成功开通或续订本产品的用户每月可任意选择一款月会员权益进行使用。<br>
    （2）会员期间，每月可享总价20元 （5元*4张）的全民家商城代金券，可在全民家商城中抵扣使用，每单可用1张。<br>
    （3）会员期间，享有全民家商城折扣，商城内商品全场8.8折，成功开通全民家会员微信连续包月的用户可自动获得该项福利。<br>
    （4）会员期间，享受低价保护特权，可进行差价补偿。<br>
    （5）会员期间，享受积分特权，购物可进行双倍积分累计。<br>
    （6）会员期间，可享受VIP专属客服1v1咨询解答。<br>
    （7）如无特别通知，包括全民家平台后期开发的其他会员服务。<br>
    2.服务期限<br>
    会员在约定期限内享受会员服务，服务期限到期后，会员可再次购买相关服务。会员计费周期按自然月计算，季卡时长为3个自然月。<br>
    三、账户管理<br>
    1.用户有义务妥善保管其在全民家平台申请的账号及密码，不得泄露给任何人。用户必须为其账号及密码之全部行为负责，此账号及密码项下全部行为即视为用户本身之行为。<br>
    2.本组账号及密码仅限于您个人使用，非经摘星格同意，用户不得出借、转移或让与等任何方式给其他第三人使用。<br>
    3.除非经过用户同意及法律规定，摘星格对于用户的个人数据不会加以泄露、转售或与其他机构进行交换。尽管摘星格对用户的个人信息保护作了极大努力，但是仍然不能保证现有的安全技术措施使用户的隐私信息等不受任何形式的损失。<br>
    四、费用支付<br>
    1、用户在全民家小程序上进行会员服务费用的支付，在支付完成后立即开通相应的会员服务。<br>
    2、全民家可能会根据本服务的整体规划，对本服务的收费标准、方式等进行修改和变更，前述修改、变更，全民家将在相应服务页面进行展示。您若需要获取、使用本服务，请先提前了解清楚当时关于本服务的收费标准、方式等信息。<br>
    五、退费管理<br>
    用户在购买会员服务后，如欲解除或终止本协议且申请退费，可以按照以下规定办理：<br>
    1.如需退费，请登录全民家小程序“我-联系客服”或通过摘星格客服电话400-888-8202进行处理<br>
    2.用户明确理解并同意，如用户申请退费，用户在购买会员服务时所享受的会员特权等项目自动取消，用户不得申请折算为现金进行退款。<br>
    六、知识产权<br>
    本网站上所显示、使用或提供之软件、程序、代码及内容（包括但不限于文字、说明、图画、图片、图形、录像、图表、课程资源、页面设计、网站规划与安排等）之专利权、著作权、商标权、商业秘密及其他知识产权均属于摘星格或其他权利人所有，非经摘星格或其他权利人事先书面授权同意，用户不得复制、公开传播、公开播送、公开上映、改作、编辑、转载、链接、引用、抓取、解编、反向破译或其他方式之使用。如有违反，除应承担法律责任外，还应承担对摘星格造成的全部损失。<br>
    七、争议解决<br>
    协议中如有其他未尽事宜，双方以全民家的公示内容为准，其他未尽事宜或执行过程中发生的其他问题由双方协商解决，协商不成的，双方可提交摘星格所在地有管辖权的法院诉讼解决。<br>
    八、其他事宜<br>
    1.摘星格有权根据法律法规的变化、网站以及相应的全民家运营的需要不时地对本协议以及本站的内容进行修改，会员可在全民家平台上查看最新的协议。用户有义务及时关注并阅读最新版的协议及网站公告。如用户不同意更新后的协议，可以立即向摘星格进行反馈且应立即停止接受摘星格依据本协议提供的服务。如用户继续使用全民家小程序提供的服务的，即视为同意更新后的协议。<br>
    2.本协议自用户点击“同意”“接受”且将所选会员服务的款项支付至摘星格账户后生效。<br>
    3.本协议所有条款的标题仅为方便而设，不具法律或契约的效果。<br>
    `,
  yinsi6: `
    <div class="rulesDes2">
        <dl class="agreement">
          <div>
            <p>本版发布日期：【2023-06-26】
              <br>生效日期：【2023-06-26】
            </p>

            <p>
              欢迎您使用我们的产品和服务！咪咕数字传媒有限公司（以下简称“我们”或“咪咕公司”）作为咪咕文化科技有限公司的子公司，系“咪咕阅读”的运营主体。我们作为上述产品和服务的运营者，深知个人信息对用户（以下简称“您”）的重要性，故我们将严格按照法律法规的规定，保护您的个人信息及隐私安全。
            </p>
            <p>鉴于此，我们制定本隐私政策并特别提示：希望您在使用上述产品及相关服务前仔细阅读并理解本隐私政策，以便作出适当的选择。</p>
          </div>
          <dt style="text-align: center;"><b class="no-underline">—— 适用范围 ——</b></dt>
          <div>
            <p>
              本隐私政策适用于“咪咕阅读”提供的所有功能及服务，咪咕文化科技有限公司及其子公司就其向您提供的产品或服务未设独立隐私政策的，则本隐私政策同样适用于该部分产品或服务；如单独设有隐私政策/协议的，则相应的产品或服务适用相应隐私政策。
            </p>

          </div>
          <dt style="text-align: center;"><b class="no-underline">—— 隐私政策摘要 ——</b></dt>
          <div>
            <p>我们将会通过本隐私政策向您介绍不同场景下，我们如何处理您的个人信息。当您开启/使用“咪咕阅读”时，为实现您选择使用的功能、服务，或为遵守法律法规的要求，我们会处理相关信息。</p>
            <p>
              除为实现“咪咕阅读”基本功能、服务所需的信息和根据法律法规要求所必需的信息之外，您可以拒绝向我们提供或拒绝我们处理其他信息，但这可能导致我们无法提供对应功能、服务，我们将在隐私政策中逐项说明相关情况，其中，有关您个人信息权益的重要内容已用加粗形式提示，请特别关注。
            </p>
            <p>
              除本隐私政策外，在特定场景下，我们还会通过即时告知（含弹窗、页面提示等）、功能更新说明等方式，向您说明对应的信息收集目的、范围及使用方式，这些即时告知及功能更新说明等构成本隐私政策的一部分，并与本隐私政策具有同等效力。
            </p>
            <p>本摘要将帮助您简洁、直观地了解我们是如何收集、使用、共享和保护您的个人信息以及您可以如何管理您的个人信息。本摘要为《咪咕阅读隐私政策》的一部分，如您希望了解我们详细的隐私政策，请查阅摘要后的隐私政策正文。
            </p>




            <p><b class="no-underline">1、我们如何收集和使用您的个人信息</b></p>
            <p>
              <b class="no-underline">（1）账号信息与联系方式</b>
            </p>
            <p>
              <b class="no-underline">注册登录：</b>
              当您使用“咪咕阅读”时，我们需要您通过手机号码进行账号注册、登录。当您使用第三方账号登录时，我们会通过您在第三方平台（包括微信账号、QQ账号、微博账号、华为账号、AppleId苹果账号)注册的公开信息（通常包括设备标识、头像、昵称以及您授权的其他信息）完成第三方账号登录，首次第三方授权登录需要绑定手机号进行验证。
            </p>
            <p>
              <b class="no-underline">账号管理：</b>
              您还可以完善您的网络身份识别信息（头像、昵称和密码），您还可以根据自身需求额外补充您的个人资料（地址信息），这将有助于我们帮助您完成注册。但如果您不提供上述信息，不会影响您使用我们产品/服务的基本功能。
            </p>

            <p>
              <b class="no-underline">（2）麦克风/录音</b>
            </p>
            <p>
              <b class="no-underline">使用目的：</b>
              麦克风为敏感权限，我们只有在用户使用到麦克风录制音频的相应功能时，才会向您申请，经您授权后开启麦克风权限收集您的录音信息。
            </p>
            <p>
              <b class="no-underline">使用场景：</b>
              经您授权，我们会使用您的麦克风权限收集您的录音信息，用于帮助您完成相关功能；如语音搜索、比特书房、AI朗读播放页声音复刻功能。
            </p>
            <p>
              <b class="no-underline">特别说明：</b>
              我们仅在您使用对应功能时收集相应信息，通过申请麦克风权限以便获得声音，以提供对应的识别后续的交互功能。不同的系统显示的名称可能有所差异，如麦克风、录音权限等。
            </p>

            <p>
              <b class="no-underline">（3）相机</b>
            </p>
            <p>
              <b class="no-underline">使用目的：</b>
              相机为敏感权限，我们只有在使用摄像头扫码进行扫一扫搜索、本地图片上传并发布相关功能时才会向您申请，经您授权后开启。
            </p>
            <p>
              <b class="no-underline">使用场景：</b>我们仅在您使用对应功能时申请，例如使用相机扫码进行扫一扫搜索、本地图片上传并发布时需要使用该权限的相关功能。
            </p>
            <p>
              <b class="no-underline">特别说明：</b>
              不同的系统显示的名称可能有所差异，如相机、摄像头等。您可以自行选择开启或关闭相机的授权，一旦关闭您将可能无法使用扫码、拍照等功能，但不会影响您使用我们产品/服务的基本功能。
            </p>

            <p>
              <b class="no-underline">（4）相册（iOS）/存储（安卓）</b>
            </p>
            <p>
              <b class="no-underline">使用目的：</b>
              经您授权，我们使用您的iOS相册权限/安卓存储权限，以便您使用下载书籍、有声读物、图片保存、进行图片海报分享、本地图片上传并发布、书架本地导入和wifi传书功能。
            </p>
            <p>
              <b class="no-underline">使用场景：</b>
              我们仅在您使用对应功能时申请该权限，例如下载书籍、有声读物、图片保存、进行图片海报分享、本地图片上传并发布、书架本地导入（安卓）和wifi传书（安卓）时。
            </p>
            <p>
              <b
                class="no-underline">特别说明：</b>不同的系统显示的名称可能有所差异，如IOS系统相册权限、安卓系统存储权限。您可以自行选择开启或关闭相册/存储的授权，关闭后您将可能无法上传或保存图片，但不会影响您使用我们产品/服务的基本功能。
            </p>

            <p>
              <b class="no-underline">（5）浏览使用信息</b>
            </p>
            <p>
              <b class="no-underline">概要：</b>
              浏览使用信息主要包括：浏览记录、搜索记录、点赞/分享/评论/互动、关注/预约记录、购买记录、播放/下载记录、内容上传/发布记录、访问日期和时间、分享、交易行为记录等用户使用APP时产生的信息。
            </p>
            <p>
              <b class="no-underline">使用目的：</b>
              在您使用我们的产品及服务时，我们收集您的上述使用信息，并作为有关操作日志、服务日志保存，我们收集这些信息是为了向您更好地提供我们最核心的服务内容展示/播放/下载服务。此外，我们会根据您的浏览使用行为向您推荐您可能更感兴趣的信息或广告。请您知悉，浏览使用信息无法标识您的个人敏感信息。
            </p>

            <p>
              <b class="no-underline">（6）设备信息标识</b>
            </p>
            <p>
              <b class="no-underline">使用目的：</b>
              为保障“咪咕阅读”正常运行，当您使用“咪咕阅读”服务时，我们会收集包括您终端用户的设备属性信息【SDK版本、运营商信息、IP地址、系统平台、系统版本、应用包名及版本、应用前后置时间、设备唯一标识、iOS广告标识符（IDFA/IDFV）、网卡（MAC）地址、国际移动设备识别码（IMEI）、匿名设备标识符(
              OAID) 、国际移动用户识别码（IMSI）、安卓系统封装用户ID、设备产商、设备型号、设备语言、应用列表信息、基站信息、传感器信息、 蓝牙信息、WIFI状态信息、WIFI名称、WIFI
              SSID、CPU信息、内存信息、设备信息、电池使用情况、获取运行中进程信息】、软件版本号、网络接入方式及类型、操作日志在内的信息。
            </p>
            <p>
              <b class="no-underline">特别说明：</b>
              以上信息是我们通过“咪咕阅读”向您提供服务所必须收集的基本信息。获取这些信息会请求您授权咪咕阅读iOS系统使用追踪IDFA/IDFV权限。
            </p>

            <p>
              我们收集的均为非基础功能所需的个人信息，且仅在您使用或开启相关功能，或满足组织管理需要，我们才会收集、使用相关个人信息。除非是为实现业务功能或法律法规要求所必需的必要信息，您均可以拒绝提供且不影响其他功能或服务。
            </p>
            <p>我们收集的个人信息清单详见:&nbsp;<a
                href="https://wap.cmread.com/nap/p/pg/4289/BR016858/perInfoCollList.htm">《咪咕阅读收集个人信息清单》</a></p>
            <p>我们申请用户授权的权限均为非基础功能所需的权限，只有用户使用具体功能时才申请调用。APP届时会通过弹窗方式申请用户授权同意，您如果拒绝授权，将无法使用相应功能，但不影响您正常使用其他功能。</p>
            <p>我们申请的应用权限详见:&nbsp;<a
                href="https://wap.cmread.com/nap/p/pg/4289/BR016858/applicPermisAndUse.htm">《应用权限申请与使用情况说明》</a></p>
            <p></p>
          </div>
          <div>
            <p><b class="no-underline">2、我们如何共享您的信息</b></p>
            <p>
              请您知悉，我们为您所提供的产品/服务大多是无法单独完成的，因此我们可能会与我们的关联公司以及其他合作商等第三方共享或委托其处理您的部分个人信息。目前，除再次获得您明确同意或授权外，此类共享或委托处理涉及的主要情形包括:根据行政、司法等有权机关的要求提供必要信息;为提供账号一致性服务与关联公司共享。必要信息如为提供快递、
              第三方支付等服务与有关合作商、第三方商家、支付机构等共享有关的必要信息等。
              我们会与其达成严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。对干我们使用的第三方的软件工具开发包(“SDK”)，您可以点击查阅<a
                href="https://wap.cmread.com/nap/p/pg/4289/BR016858/thirInfoShareList.htm">《咪咕阅读与第三方共享个人信息清单》</a>了解详情。
            </p>
          </div>
          <div>
            <p><b class="no-underline">3、您如何管理您的信息</b></p>
            <p>您有权管理您的个人信息，包括查询、更正、补充、复制和删除您的账号信息、地址信息、搜索记录、评论信息，改变您授权同意的范围或撤回授权，以及注销您的账号。您可以通过我们的App中提供的功能设置进行上述操作。</p>
            <p>如果您无法通过上述方式进行管理，您可以通过查看隐私政策正文第十条“如何联系我们”内载明的联系方式向我们反馈，我们将在15个工作日内回复您的要求。</p>
          </div>
          <div>
            <p><b class="no-underline">4、我们如何做好未成年人保护</b></p>
            <p>我们非常重视未成年人个人信息的保护。由于我们的服务主要面向成人，如您为未成年人的，建议您请您的监护人仔细阅读本隐私政策，并在征得您的监护人同意的前提下使用我们的服务或向我们提供信息。</p>
            <p>我们也提供了青少年模式，为未成年人提供适宜浏览的内容，可通过监护人设置青少年模式后，进行青少年模式浏览。</p>
          </div>
          <div>
            <p><b class="no-underline">5、您如何联系我们</b></p>
            <p>如果您对本个人信息保护政策有任何疑问、意见或建议，可通过在线客服或个人信息保护专员【service@migu.cn】联系我们，我们将尽快审核所涉问题，并在验证您的真实身份后在十五天内回复。</p>
          </div>
          <div>
          </div>
          <dt style="text-align: center;"><b class="no-underline">—— 隐私政策正文 ——</b></dt>
          <div>
            <p>咪咕公司非常重视您的个人信息保护，并会尽全力保护您的个人信息。请您在使用“咪咕阅读”产品及服务前，仔细阅读并了解本《咪咕阅读隐私政策》</p>
          </div>
          <dt style="text-align: center;"><b>第一部分：定义及名词解释</b></dt>
          <div>
            <p><b class="no-underline">咪咕公司：</b>指咪咕数字传媒有限公司，运营“咪咕阅读”、“咪咕云书店”APP，注册地址为：浙江省杭州市西湖区西溪银座1幢1层101室；</p>

            <p><b class="no-underline">个人信息：</b>
              以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括<b>姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、交易信息。</b>
            </p>

            <p><b class="no-underline">个人敏感信息：</b>
              一旦泄露、非法使用，容易导致自然人人格尊严受到侵害或者人身、财产安全受到危害或歧视性待遇的个人信息。个人敏感信息包括<b>身份证件号码、个人生物识别信息、宗教信仰、特定身份、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、医疗健康生理信息、交易信息、14岁以下（含）儿童的个人信息。</b>
            </p>
            <p><b
                class="no-underline">唯一设备标识符：</b>是指由设备制造商编入到设备中的一串字符，可用于以独有方式标识相应设备。具体包含有：国际移动设备识别码(IMEI)、匿名设备标识符(OAID)、Android
              ID、iOS广告标识符(IDFA)、网卡(MAC)地址、国际移动用户识别码(IMSI)、OpenUDID、GUID。</p>
            <p><b
                class="no-underline">用户画像：</b>通过收集、汇聚、分析个人信息，对某特定自然人个人特征，如其职业、经济、健康、教育、个人喜好、信用、行为方面做出分析或预测，形成其个人特征模型的过程。直接使用特定自然人的个人信息，形成该自然人的特征模型，称为直接用户画像。使用来源于特定自然人以外的个人信息，如其所在群体的数据，形成该自然人的特征模型，称为间接用户画像。
            </p>
            <p><b class="no-underline">去标识化：</b>通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经去标识化处理后所得的信息不属于个人信息。</p>
          </div>
          <dt style="text-align: center;"><b>第二部分：隐私政策</b></dt>
          <div>
            <p>本政策包含以下内容：</p>
            <p>
              一、我们收集了哪些个人信息
              <br>二、我们如何使用您的个人信息
              <br>三、我们如何使用 Cookie 和同类技术
              <br>四、我们如何共享、转让、公开披露您的个人信息
              <br>五、我们如何保存及保护您的个人信息
              <br>六、您的权利
              <br>七、我们如何处理未成年人的个人信息
              <br>八、您的个人信息如何在全球范围转移
              <br>九、本政策如何更新
              <br>十、如何联系我们
            </p>
          </div>
          <dt style="text-align: center;"><b>一、我们收集了哪些个人信息</b></dt>
          <div>
            <p>
              咪咕公司向您提供的下列产品和服务需要依赖部分信息才得以运行，如您选择使用某项服务的，则需要向我们提供或允许我们收集相应的必要信息，如您拒绝提供的，可能会导致我们无法提供该项服务，但不会影响您使用其他产品和服务。您可以点击查阅<a
                href="https://wap.cmread.com/nap/p/pg/4289/BR016858/perInfoCollList.htm">《咪咕阅读收集个人信息清单》</a>了解详情。</p>
            <p>根据《常见类型移动互联网应用程序必要个人信息范围规定》，咪咕阅读APP属于电子图书类，基本功能服务为“电子图书搜索、阅读”，无须个人信息，即可使用基本功能服务。</p>
            <div class="read">
              <p><b class="no-underline">（一） 咪咕阅读：</b></p>
              <p><b class="no-underline">1、保障“咪咕阅读”正常运行</b></p>
              <p>
                为保障“咪咕阅读”正常运行，当您使用“咪咕阅读”服务时，我们会收集包括您终端用户的设备属性信息【SDK版本、运营商信息、IP地址、系统平台、系统版本、应用包名及版本、应用前后置时间、设备唯一标识、iOS广告标识符（IDFA/IDFV）、网卡（MAC）地址、国际移动设备识别码（IMEI）、匿名设备标识符(
                OAID) 、国际移动用户识别码（IMSI）、安卓系统封装用户ID、设备产商、设备型号、设备语言、应用列表信息、基站信息、传感器信息、 蓝牙信息、WIFI状态信息、WIFI名称、WIFI
                SSID、CPU信息、内存信息、设备信息、电池使用情况、获取运行中进程信息】、软件版本号、网络接入方式及类型、操作日志在内的信息。<b
                  class="no-underline">以上信息是我们通过“咪咕阅读”向您提供服务所必须收集的基本信息。</b></p>
              <p><b class="no-underline">2、注册、认证、登录“咪咕阅读”服务</b></p>
              <p>（1）当您注册、登录“咪咕阅读”服务时，<b
                  class="no-underline">您可以使用“一键登录”功能，通过授权我们收集、验证您的手机号码，便于我们为您提供快捷的登录服务。</b>如您不选择“一键登录”方式，您还可以使用其他登录方式，包括手机验证码、密码登录、第三方账号登录。
                <br>如果您选择通过手机号创建账号，我们将通过发送短信验证码来验证您的身份是否有效，并且您可根据自身需求完善网络身份识别信息（头像、昵称、密码），收集这些信息是为了帮助您完成注册。您还可根据自身需求填写昵称信息完善您的个人信息。当您通过同样认证信息登录我们运营的咪咕阅读或咪咕云书店时，我们将显示相同的头像及昵称，您通过咪咕阅读或咪咕阅读任一APP修改头像和昵称，咪咕阅读和咪咕云书店都会生效。
              </p>
              <p>
                （2）您也可以使用第三方账号（包括微信账号、QQ账号、微博账号、华为账号、AppleId苹果账号）登录进入“咪咕阅读”，您此时需授权我们获取您在第三方平台注册的公开信息（通常包括头像和昵称以及您授权的其他信息），并在您同意本隐私政策后将您的第三方账号与您的咪咕账号绑定，使您可以通过第三方账号直接登录并使用本产品和相关服务（首次第三方授权登录需要绑定手机号进行验证）。
              </p>
              <p>（3）当您登录“咪咕阅读”第三方小程序服务时，在您授权同意后，我们将获得您在该第三方平台注册的公开信息（微信小程序包括头像、昵称和手机号码；百度、支付宝小程序及快应用仅获取手机号）。</p>

              <p><b class="no-underline">3、提供个性化内容推荐服务</b></p>
              <p>（1）页面访问、内容使用、社交互动、关注、分享，上述功能里您使用行为信息和使用时长信息；</p>
              <p>（2）反馈、发布、点赞、评论、笔记、投月票、打赏，上述功能里您主动提供的信息；</p>
              <p>我们可能会将上述信息与来自我们其他服务的信息结合，通过算法分析后向您进行个性化推荐、展示或推送您可能感兴趣的特定内容信息或服务。<br>
                如您不同意提供前述服务，您可以通过“<b>我的-&gt;设置-&gt;偏好设置</b>”路径选择关闭，但不影响您正常使用“咪咕阅读”的其他功能。<br>
                <b class="no-underline">请您了解，我们收集、使用上述信息时已按照相关法律法规规定进行了去标识化处理。</b>
              </p>
              <b class="no-underline">注：以上描述不适用第三方小程序及快应用，小程序及快应用没有个性化内容推荐服务，不会基于您的信息做定向推送。</b>
              <p></p>
              <p><b class="no-underline">4、开展营销活动</b></p>
              <p>
                当您参加我们举办的营销活动时，为了确保您能收到营销活动所涉及的实物或现金奖励，我们可能会要求您提供包括<b>姓名、通信地址、银行卡卡号、银行卡开户行、手机号码</b>在内的信息。此外，按照《中华人民共和国个人所得税法》的相关规定，可能还需要您提供身份证号码，以便我们为您代扣代缴相关税费。如果您拒绝提供以上信息，我们将可能无法向您发放相关奖励。
              </p>
              <p><b class="no-underline">5、提供上传云盘服务</b></p>
              <p>
                我们使用了“中国移动云盘”提供的云存储服务。当您上传本地导入文件或云笔记到“中国移动云盘”云盘时，我们会使用您当前登录的电话号码登录“中国移动云盘”，并将您选定的本地文件或云笔记上传到“中国移动云盘”服务器下您的帐号空间内。如您不使用云盘服务，我们将不会收集和使用上述个人信息，也不会向“中国移动云盘”提供您的个人信息、本地文件及笔记信息。
              </p>
              <p><b class="no-underline">6、广告服务</b></p>
              <p>
                我们会在咪咕阅读内向您展示广告内容（标注“广告”字样）。在使用过程中，我们可能会收集您设备的设备品牌、安卓系统封装用户ID、设备型号、系统类型、系统版本、设备屏幕尺寸信息、iOS广告标识符（IDFA/IDFV），用于广告数据监测及投放。我们可能与进行推广和广告投放的合作伙伴共享信息，但仅会向其提供去标识化的终端唯一标识信息。您可以在“<b>我的-&gt;设置-&gt;隐私设置-个性化广告推荐</b>”选择关闭，当您关闭后，您看到的广告数量不会减少，但广告的相关度会降低。
              </p>
              <p><b class="no-underline">7、比特分身人物形象</b></p>
              <p>当您体验比特分身人物形象设计服务时，需要请求您的<b>相机权限，收集人脸BS信息、头部姿势数据</b>。如果您的（iOS）设备支持FaceID特性，我们会使用TrueDepth
                APIs，收集该信息用于驱动3D人物模型的面部及头部姿势。除此之外无任何其它用途。收集的人脸BS信息、头部姿势数据不会共享到任何第三方。如果您不使用比特分身人物形象录制表情包，我们不会主动使用TrueDepth
                APIs。当您使用比特分身人物形象制作功能时（安卓），HUAWEI AR Engine SDK为您提供运动跟踪、环境跟踪、人体和人脸跟踪等AR基础能力。</p>
              <p>如果您需要保存您录制的比特分身人物表情，需要请求您的安卓手机存储权限或iOS手机相册权限。以上服务您拒绝授权提供时，将无法使用表情制作功能，但不影响您正常使用其他功能。</p>

              <p><b class="no-underline">8、传感器应用场景声明</b></p>
              <p>
                当您在浏览内容、参与活动时，为了保证服务正常运行，通过传感器判断设备状态并识别为正常终端设备，我们使用了加速度传感器、游戏旋转矢量传感器、线性加速度传感器、陀螺仪传感器，以上设备信息仅用于保障基础服务的正常运行，如您拒绝提供，将影响基础服务的正常实现。
              </p>

              <p><b class="no-underline">9、自启动和关联启动说明</b></p>
              <p>
                （1）为保障“咪咕阅读”的稳定运行，我们会对您已安装的“咪咕阅读”APP进行更新提醒，并提供“咪咕阅读”安卓系统应用版本的更新下载服务，在安卓系统内需要您授权我们应用自启动，使其保持下载应用后的及时提醒更新相关事宜。
              </p>
              <p>（2）为确保本应用处于关闭或者后台运行状态下可以正常接收客户端推送的广播消息，本应用须使用自启动能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是实现推送功能所必要的。 </p>
              <p>
                （3）为了带给您更好的听书/TTS播放体验，当您操作系统媒体按键听书时会关联启动app的播放服务，如车载连接蓝牙、在手机媒体中心点击播放、连接蓝牙耳机、APP熄屏听书，是媒体服务功能所必要的。需要说明的是，若您不使用听书/TTS播放功能，则不会关联启动对应服务。
              </p>
              <p>若您不同意提供前述服务，您可以通过关闭“【我的-&gt;设置-&gt;隐私设置-&gt;通知设置】”或不选择下载更新安装或不打开分享链接，关闭自启动或关联启动功能，但不影响您正常使用“咪咕阅读”的其他功能。
              </p>


              <p><b class="no-underline">10、对于使用咪咕阅读的用户，您在使用咪咕阅读时，为提供、处理、维护我们提供服务之目的，本软件可能向您申请获取您终端设备的权限，您可以点击查阅<a
                    href="https://wap.cmread.com/nap/p/pg/4289/BR016858/applicPermisAndUse.htm">《咪咕阅读应用权限申请与使用情况说明》</a>了解详情。当然您可以拒绝开启相关权限，或在设备的系统设置-应用详情页-权限设置中关闭相应权限，但不影响您使用“咪咕阅读”其他功能。</b>
              </p>

              <p><b class="no-underline">11、第三方SDK收集使用信息说明</b></p>
              <p>
                为保障咪咕阅读的稳定运行或实现功能，我们可能会接入由第三方提供的软件开发包（SDK）实现前述目的。我们会尽到审慎义务，对合作方获取信息的软件工具开发包（SDK）进行严格的安全监测，以保护数据安全。我们接入的第三方SDK主要服务于您以及其他的用户的需求，因此在满足新的服务需求及业务功能变更时，我们可能会调整我们接入的第三方SDK。我们会及时在本说明中向您公开说明接入第三方SDK的最新情况。您可以点击查阅<a
                  href="https://wap.cmread.com/nap/p/pg/4289/BR016858/thirInfoShareList.htm">《咪咕阅读与第三方共享个人信息清单》</a>了解详情。
              </p>
            </div>
          </div>
          <dt style="text-align: center;"><b>二、我们如何使用您的个人信息：</b></dt>
          <div>
            <p><b class="no-underline">1、为您提供产品或服务</b> </p>
            <p><b class="no-underline">2、改善我们所提供的产品或服务</b></p>
            <p><b
                class="no-underline">我们会根据您的浏览及搜索记录、设备信息、位置信息、订阅信息，提取您的浏览及搜索偏好、行为习惯、位置信息特征，基于特征标签进行间接人群画像，并展示、推送信息和可能的商业广告，以便向您提供更符合您个性化需求的信息展示、搜索及交易服务。</b>
              <br>如果您不想接收我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订，但不会影响您使用其他服务。
            </p>

            <p><b class="no-underline">3、为您提供安全保障</b></p>
            <p>（1）在我们提供服务时，用于身份验证、客户服务、安全防范、存档和备份用途，确保我们向您提供的产品和服务的安全性；</p>
            <p>（2）软件认证或管理软件升级；</p>
            <p>
              <b>在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理。去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在符合相关法律法规的前提下，我们有权对包括您的个人信息在内的用户数据库进行整体化分析和利用（包括商业性利用）；</b>
            </p>
            <p>（3）我们收集您终端用户的设备属性信息将用于安全事件调查、检测和防止欺诈活动以及检测和防止网络攻击和滥用，以保障网络安全和运营安全。</p>

            <p><b class="no-underline">4、客户服务</b></p>
            <p>
              为及时处理您的投诉、建议以及其他诉求，我们需要使用您已提供的账号信息，以及时了解、处理和响应您的需求。如有必要，也可能需要使用您的手机号码或通过其他通讯方式与您取得联系。为尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的沟通、通信/通话记录及相关内容（包括账号沟通所涉信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息）。
            </p>

            <p>5、随着我们业务的发展，可能会调整提供的产品或服务。如调整变化后的产品或服务所处理个人信息的类型、目的、方式和范围发生变化的，我们会再次向您告知，并根据法律法规的相关要求征得您的同意。</p>

            <p>6、当我们要将信息用于本政策未载明的其它用途时，会事先通过弹窗方式逐项征求您的同意。</p>

            <p>7、当我们要将信息用于本政策未载明的其它用途时，会事先通过弹窗方式逐项征求您的同意。</p>
            <p><b>5、您充分知晓，以下情形中我们使用个人信息无需征得您的授权同意： </b></p>
            <p>（1）与国家安全、国防安全直接相关的；</p>
            <p>（2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p>（3）与犯罪侦查、起诉、审判和判决执行直接相关的；</p>
            <p>（4）出于维护个人信息主体或其他个人的生命、财产重大合法权益但又很难得到本人同意的； </p>
            <p>（5）所收集的个人信息是个人信息主体自行向社会公众公开的； </p>
            <p>（6）从合法的新闻报道、政府信息公开渠道公开披露的信息中收集的您的个人信息的；</p>
            <p>（7）根据您的要求签订合同所必需的；</p>
            <p>（8）用于发现、处置产品与/或服务的故障所提供的产品与/或服务的安全稳定运行所必需的；</p>
            <p>（9）为合法的新闻报道所必需的；</p>
            <p>（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
            <p>（11）法律法规规定的其他情形。</p>


          </div>
          <dt style="text-align: center;"><b>三、我们如何使用 Cookie 和同类技术</b></dt>
          <div>
            <p><b class="no-underline">（一）Cookie </b></p>
            <p>为确保“咪咕阅读”正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件，以收集、标识和存储您访问、使用本产品/服务时的信息。Cookie
              通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，我们能够更好的了解您的兴趣偏好及使用习惯。</p>
            <p>我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。</p>
            <p>您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们产品时亲自更改用户设置。</p>
            <p><b class="no-underline">（二）网站信标和像素标签 </b></p>
            <p>除 Cookie 外，我们还可能会使用网站信标和像素标签其他同类技术。我们向您发送的电子邮件可能含有链接至我们网站内容的点击
              URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
            </p>
            <p><b class="no-underline">（三）Do Not Track（请勿追踪） </b></p>
            <p>很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not Track 请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do
              Not Track，那么我们的所有网站都会尊重您的选择。</p>
          </div>
          <dt style="text-align: center;"><b>四、我们如何共享、转让、公开披露您的个人信息</b></dt>
          <div>
            <p><b class="no-underline">（一）共享</b></p>
            <p>我们不会与咪咕公司以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：</p>
            <p>1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息，共享的个人信息范围仅限于您明确同意的范围；</p>
            <p>2、去标识化处理后的信息与第三方共享，共享第三方无法重新识别此类信息的自然人主体； </p>
            <p>3、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。 </p>
            <p><b class="no-underline">
                4、与我们的关联公司共享：为便于我们共同向您提供服务，推荐您可能感兴趣的信息或保护咪咕关联公司或其他用户或公众的人身财产安全免遭侵害，</b><b>我们可能会与我们的关联公司共享、</b><b
                class="no-underline">或从关联公司获取与产品业务功能相关的个人信息，包括手机号码、昵称、头像、性别、年龄范围、页面访问、内容使用、上网记录、社交互动、订购消费</b>。用于实现更符合您个性化需求的信息展示、搜索及我们的合作伙伴的产品与服务；关联公司如要改变上述个人信息的处理目的，将再次征求您的授权同意。
            </p>
            <p>
              我们的关联公司包括咪咕文化科技有限公司现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人；以及咪咕文化科技有限公司母公司中国移动通信有限公司及其控制的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。
            </p>
            <p><b
                class="no-underline">5、与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。</b>我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。
            </p>
            <p>目前，我们与授权合作伙伴共享信息的情形可能包括：</p>
            <p><b
                class="no-underline">为实现特定功能而与业务合作伙伴共享，当软件服务提供商、智能设备提供商或系统服务提供商与我们联合向您提供服务时，为实现这一功能，我们可能会收集您的相关设备信息（包括但不限于硬件型号、操作系统版本号、IMEI、MAC），并在去标识化处理后提供给前述合作伙伴。</b>您可以拒绝提供此类信息，不使用相应服务，但不会影响您使用其他服务。
            </p>
            <p>对我们与之共享信息的公司、组织和个人，我们会与其签署相应的保密协定，要求他们按照我们的说明、本个人信息保护政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
            <p>我们的关联公司及授权合作伙伴可能有其独立的隐私政策和用户协议，我们建议您阅读并遵守该第三方的用户协议及隐私保护政策。</p>
            <p><b>（二）转让</b> </p>
            <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
            <p>1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
            <p>
              2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们向您告知接收方的名称或者姓名和联系方式并要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
            </p>
            <p><b>（三）公开披露 </b> </p>
            <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
            <p>1、获得您明确同意后； </p>
            <p>
              2、基于法律的披露：我们可能会在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，向持有强制性文件的主体公开披露您的个人信息。包括<b>姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、帐号密码、财产信息、征信信息、行踪轨迹、交易信息</b>我方获取的内容。
            </p>
            <p><b>（四）停止运营 </b> </p>
            <p>如果我们停止运营产品或服务，将及时停止继续收集您的个人信息。我们会逐一送达通知或以公告的形式向您发送停止运营的告知，并对我们所持有的与已关停的产品或服务相关的个人信息进行删除或匿名化处理，法律法规另有规定除外。
            </p>


          </div>
          <dt style="text-align: center;"><b>五、我们如何保存及保护您的个人信息</b></dt>
          <div>
            <p><b class="no-underline">（一）保存期限</b></p>
            <p>
              我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非经过您的同意延长保留期或受到法律的允许。如果您注销账号或主动删除上述信息，我们将按照法律、法规规定的最短期限保留您的现有个人信息，在法律法规要求的最短保存期限内，除法律法规另有规定外，我们将停止对您的个人信息进行除存储和采取必要的安全保护措施外的处理/使用。当您的个人信息超出上述保存期限，我们将会对其进行删除或匿名化处理。
            </p>
            <p><b class="no-underline">（二）存放地域</b></p>
            <p>您的个人信息均储存于中华人民共和国境内；如需跨境传输，我们将会单独征得您的授权同意。</p>
            <p><b class="no-underline">（三）安全保护措施</b></p>
            <p>
              1、我们会以“最小化”原则收集、使用、存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。我们会严格按照如下《网络安全法》《全国人民代表大会常务委员会关于加强网络信息保护的决定》《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等法律法规的相关要求，建立信息安全保障制度，采取技术措施和其他必要措施保护您的个人信息安全。
            </p>
            <p>
              2、咪咕公司将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。如：使用加密技术确保数据的保密性；使用受信赖的保护机制防止数据遭到恶意攻击；对相关访问进行授权；定期开展数据安全专项检查的安全防护与检测措施；对员工进行数据安全的意识培养和安全能力的培训，加强员工对于保护个人信息重要性的认识。
            </p>
            <p>
              3、请您理解：互联网环境并非百分之百安全，我们将尽力确保您发送给我们的任何信息的安全性，但由于技术的限制及可能存在的各种恶意手段，即便我们已经按照法律法规相关规定所要求的加强安全措施，也不可能始终保证信息百分之百安全。
            </p>
            <p>
              4、在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将及时将事件相关情况以邮件、信函、电话、推送通知的方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
            </p>
          </div>
          <dt style="text-align: center;"><b>六、您的权利</b></dt>
          <div>
            <p><b class="no-underline">（一）查询、更正和补充您的个人信息</b></p>
            <p>1、如果您希望访问或编辑账户中的个人资料信息、更改密码，您可以随时登录个人账户，通过“设置”完成上述操作。</p>
            <p>2、如果您希望查询个人的浏览记录、搜索记录、上传内容、订单信息，您可以随时登录“咪咕阅读”APP个人账户，通过“我的”完成上述操作；第三方小程序及快应用暂时不支持查询以上信息。</p>
            <p>
              3、在您通过用户身份验证，且更正不影响信息的客观性和准确性的情况下，您有权对错误或不完整的信息作出更正、更新及补充。您可以自行在“咪咕阅读”产品中进行前述操作，或通过本政策第十条“如何联系我们”内载明的联系方式进行意见反馈、联系客服并将更正、更新、补充申请提交给我们。
            </p>
            <p>4、微信、百度、支付宝平台小程序及快应用的头像、昵称为系统自动生成的默认头像、昵称，暂不支持修改；如果您希望修改小程序的绑定手机号，您可以联系客服进行处理。</p>
            <p><b class="no-underline">（二）删除个人信息</b></p>
            <p>您在我们的产品与/或服务页面中可以直接清除或删除的信息，包括浏览信息、订购信息、收藏信息，删除个人信息途径及方式请见本政策下各应用产品和服务内容部分关于撤回授权的说明。</p>
            <p>在以下情形中，您可以通过本隐私政策第十条“如何联系我们”内载明的方式，向我们提出删除个人信息的请求：</p>
            <p>1、如果我们处理个人信息的行为违反法律法规； </p>
            <p>2、如果我们收集、使用您的个人信息，却未征得您的同意；</p>
            <p>3、如果我们处理个人信息的行为违反了与您的约定；</p>
            <p>4、如果您不再使用我们的产品或服务，或您注销了帐号；</p>
            <p>5、如果我们不再为您提供产品或服务，或者保存期限已届满。</p>
            <p><b>当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</b></p>
            <p><b>若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。</b></p>
            <p><b class="no-underline">（三）撤回同意</b></p>
            <p>每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。</p>
            <p>1、您可以通过删除信息、关闭设备功能、在咪咕网站或“咪咕阅读”APP中进行隐私设置的方式改变您授权我们继续收集个人信息的范围或撤回您的授权 。
              通过您个人设备撤回对“咪咕阅读”APP授权的路径：<b
                class="no-underline">“我的-&gt;设置-&gt;隐私设置-&gt;权限设置”</b>改变您授权我们继续收集个人信息的范围或撤回您的授权。通过第三方小程序撤回您授权的路径：小程序右上角的“<b>更多-&gt;设置-&gt;用户信息</b>”。您也可以通过注销账户的方式<b
                class="no-underline">（我的-&gt;设置-&gt;隐私设置-&gt;注销咪咕阅读账号；第三方小程序及快应用：联系客服进行注销）</b>，撤回我们继续收集您个人信息的全部授权。</p>
            <p>2、当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。</p>
            <p><b class="no-underline">（四）注销帐号</b></p>
            <p>您注销帐号之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。如您希望注销您的咪咕账号，您可以通过“咪咕阅读”APP进行注销，具体注销帐号的方式<b
                class="no-underline">（我的-&gt;设置-&gt;隐私设置-&gt;注销咪咕阅读账号；第三方小程序及快应用：联系客服并提供您的手机号/用户ID进行处理）</b>，具体请您参阅《咪咕用户服务协议》相关指引。
            </p>
            <p><b class="no-underline">（五）个人信息副本获取</b></p>
            <p>
              如您需要您的个人信息的副本，您可以通过本政策“十、如何联系我们”中所列的反馈渠道联系我们，在核实您的身份后，我们将向您提供您在“咪咕阅读”中的个人信息副本（包括基本资料、身份信息），但法律法规另有规定的或本政策另有约定的除外。
            </p>
          </div>
          <dt style="text-align: center;"><b>七、我们如何处理未成年人的个人信息</b></dt>
          <div>
            <p>
              （一）我们非常重视对未成年人个人信息的保护。若您是14周岁以下的未成年人，在使用我们的服务前，请您的监护人仔细阅读本隐私政策，并事先取得您监护人的同意。我们将根据国家相关法律法规的规定保护未成年人的个人信息。我们的部分服务可能需要您提供特定的个人敏感信息来实现特定功能（包含专门面向儿童的功能板块，或者需要用户输入生日、年龄信息这些能识别用户年龄的产品或服务）。若您选择不提供该类信息，则可能无法正常使用服务中的特定功能，但不影响您使用服务中的其他功能。若您主动提供您的个人敏感信息，即表示您同意我们按本政策所述目的和方式来处理您的个人敏感信息。
            </p>
            <p>（二）对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。</p>
            <p>（三）如果我们发现自己在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。</p>
          </div>
          <dt style="text-align: center;"><b>八、您的个人信息如何在全球范围转移</b></dt>
          <div>
            <p>我们依据本政策在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。如果需要将您的个人信息转移到境外，我们将另行获得您的授权同意。在此情况下我们会依据本政策以使您的个人信息得到足够的保护。
            </p>
          </div>
          <dt style="text-align: center;"><b>九、本政策如何更新</b></dt>
          <div>
            <p>（一）我们的个人信息保护政策可能变更。未经您明确同意，我们不会削减您按照本个人信息保护政策所应享有的权利。</p>
            <p>
              （二）我们会在网站、APP内发布对本政策所做的任何变更。并在更新的条款生效后，在您下次登录网站或启动APP时，我们会以显著的弹窗形式提醒您更新内容，以便您及时了解本隐私政策的最新版本，并由您确认是否同意。如果更新的内容需要您授权采集音视频、<b
                class="no-underline">获取通讯录、联系方式、地理位置</b>权限，将造成您在本隐私政策下权利的实际性减少或重大变更（例如个人信息处理的种类、目的、方式变更），仍会再次以显著方式征求您的同意。
            </p>
            <p>（三）如无特殊说明，修订后的隐私政策自公布之日起生效。</p>
          </div>
          <dt style="text-align: center;"><b>十、如何联系我们</b></dt>
          <div>
            <p>如果您对本个人信息保护政策有任何疑问、意见或建议，可通过在线客服或个人信息保护专员【service@migu.cn】联系我们，我们将尽快审核所涉问题，并在验证您的真实身份后在十五天内回复。</p>
            <p>如果您对于我们的回复不满意，您还可以通过咪咕数字传媒有限公司所在地有管辖权的法院提起诉讼的方式来寻求解决。</p>
          </div>
        </dl>
      </div>
    `,
  yinsi7: `
    提示：在使用全民家会员服务之前，请您先认真阅读本协议，在充分理解并同意遵守本协议各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款后再进行本平台会员服务的购买和使用。免除或者限制责任的条款，您应重点阅读。如您对本协议有任何疑问的，应向全民家小程序客服（客服电话：400-888-8202）咨询。<br>
    一、协议确认<br>
    1.本协议是您（以下称“您”或“用户”）基于对全民家的认可与全民家小程序的开发者【北京摘星格信息科技有限公司】（以下简称“摘星格”）之间就全民家平台的会员服务等相关事宜所订立的协议。<br>
    2.请您仔细阅读本会员服务协议，当您按照本协议提示填写信息、阅读并同意本协议且完成全部订立程序后，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即构成对双方有约束力的法律文件。您承诺接受并遵守本协议的约定，届时您不能以未阅读本协议的内容等理由，主张本协议无效或要求撤销本协议。<br>
    3.如果您在18周岁以下，则只能在父母或监护人的监护参与下才能使用本会员服务。若您不具备该主体资格，则您的监护人应承担因此而导致的一切后果。<br>
    二、服务内容<br>
    1.全民家平台提供的会员服务内容如下：<br>
    （1）会员期间，每月可享总价20元 （5元*4张）的全民家商城代金券，可在全民家商城中抵扣使用，每单可用1张。<br>
    （2）会员期间，享有全民家商城折扣，商城内商品全场8.8折，成功开通全民家会员微信连续包月的用户可自动获得该项福利。<br>
    （3）会员期间，享受低价保护特权，可进行差价补偿。<br>
    （4）会员期间，享受积分特权，购物可进行双倍积分累计。<br>
    （5）会员期间，可享受VIP专属客服1v1咨询解答。<br>
    （6）如无特别通知，包括全民家平台后期开发的其他会员服务。<br>
    2.服务期限<br>
    会员在约定期限内享受会员服务，服务期限到期后，会员可再次购买相关服务。会员计费周期按自然月计算，季卡时长为3个自然月。<br>
    三、账户管理<br>
    1.用户有义务妥善保管其在全民家平台申请的账号及密码，不得泄露给任何人。用户必须为其账号及密码之全部行为负责，此账号及密码项下全部行为即视为用户本身之行为。<br>
    2.本组账号及密码仅限于您个人使用，非经摘星格同意，用户不得出借、转移或让与等任何方式给其他第三人使用。<br>
    3.除非经过用户同意及法律规定，摘星格对于用户的个人数据不会加以泄露、转售或与其他机构进行交换。尽管摘星格对用户的个人信息保护作了极大努力，但是仍然不能保证现有的安全技术措施使用户的隐私信息等不受任何形式的损失。<br>
    四、费用支付<br>
    1、用户在全民家小程序上进行会员服务费用的支付，在支付完成后立即开通相应的会员服务。<br>
    2、全民家可能会根据本服务的整体规划，对本服务的收费标准、方式等进行修改和变更，前述修改、变更，全民家将在相应服务页面进行展示。您若需要获取、使用本服务，请先提前了解清楚当时关于本服务的收费标准、方式等信息。<br>
    五、退费管理<br>
    用户在购买会员服务后，如欲解除或终止本协议且申请退费，可以按照以下规定办理：<br>
    1.如需退费，请登录全民家小程序“我-联系客服”或通过摘星格客服电话400-888-8202进行处理<br>
    2.用户明确理解并同意，如用户申请退费，用户在购买会员服务时所享受的会员特权等项目自动取消，用户不得申请折算为现金进行退款。<br>
    六、知识产权<br>
    本网站上所显示、使用或提供之软件、程序、代码及内容（包括但不限于文字、说明、图画、图片、图形、录像、图表、课程资源、页面设计、网站规划与安排等）之专利权、著作权、商标权、商业秘密及其他知识产权均属于摘星格或其他权利人所有，非经摘星格或其他权利人事先书面授权同意，用户不得复制、公开传播、公开播送、公开上映、改作、编辑、转载、链接、引用、抓取、解编、反向破译或其他方式之使用。如有违反，除应承担法律责任外，还应承担对摘星格造成的全部损失。<br>
    七、争议解决<br>
    协议中如有其他未尽事宜，双方以全民家的公示内容为准，其他未尽事宜或执行过程中发生的其他问题由双方协商解决，协商不成的，双方可提交摘星格所在地有管辖权的法院诉讼解决。<br>
    八、其他事宜<br>
    1.摘星格有权根据法律法规的变化、网站以及相应的全民家运营的需要不时地对本协议以及本站的内容进行修改，会员可在全民家平台上查看最新的协议。用户有义务及时关注并阅读最新版的协议及网站公告。如用户不同意更新后的协议，可以立即向摘星格进行反馈且应立即停止接受摘星格依据本协议提供的服务。如用户继续使用全民家小程序提供的服务的，即视为同意更新后的协议。<br>
    2.本协议自用户点击“同意”“接受”且将所选会员服务的款项支付至摘星格账户后生效。<br>
    3.本协议所有条款的标题仅为方便而设，不具法律或契约的效果。<br>
    `,
  empower: `
    <div class="detail">
        本协议（以下简称“本协议”）是支付宝用户（以下简称“用户”或“您”）与支付宝（中国）网络技术有限公司（以下简称“支付宝”）就付款授权服务等相关事项所订立的有效合约。<strong> 您通过网络页面点击确认或其他核验流程接受本协议，即表示您与支付宝已达成协议并同意接受本协议的全部约定内容</strong>。
        在接受本协议之前，请您仔细阅读本协议的全部内容<strong>（尤其是加粗的文字部分）</strong>。如果您不同意本协议的任意内容，或者无法准确理解支付宝对条款的解释，请不要进行后续操作。如果您对本协议有疑问，请通过官方热线（4008328800）进行询问。<br />
        <strong>1 ． 服务介绍</strong><br />
        <strong>付款授权服务（以下简称“本服务”）：是指您授权特定第三方向支付宝发送扣款指令，并授权支付宝根据该特定第三方的指令或授权支付宝将扣款指令传递给支付宝客户端内其他付款渠道服务的提供主体（简称“其他付款渠道服务主体”），以从您的支付宝账户或其他付款渠道中扣取指定款项至该特定第三方指定的账户中。</strong><br />
        <strong>2 ． 注意事项</strong><br />
        2.1 您确认使用本服务的意思表示出自您的真实意愿；同时您对使用本服务过程中发出的指令的真实性及有效性负责，该指令视为您的真实意思表示且不可变更或撤销。您知晓，支付宝依照您的指令进行操作的风险由您承担。<br />
        2.2 您承诺不会利用本服务从事任何非法的或违反本协议目的或者侵犯其他第三方权益的行为，否则支付宝有权单方面终止提供本服务；如您给支付宝造成损失的，您应按照法律规定承担相应责任。<br />
        <strong>2.3  您授权特定第三方向支付宝发送扣款指令，并授权支付宝根据该特定第三方的指令或授权支付宝将扣款指令传递给其他付款渠道服务主体，以从您支付宝账户或其他支付宝客户端付款渠道中扣取指定款项至该特定第三方指定的账户。您了解并确认您授权的特定第三方包括为您提供服务的商户、商户与您共同确认的第三方，或当您未及时足额付款时，在保险公司向商户赔付您应当向商户支付的款项后，依法对您享有债权的保险公司（如有）。<br />
            2.4  在使用本服务期间，除另有约定外，您可以使用支付宝客户端付款渠道，包括支付宝账户余额、开通快捷支付功能的银行卡或其他实际支持且经收款方选择的付款渠道（如支付宝账户绑定的银行卡、支付宝红包、集分宝、余额宝、花呗等）。为确保您顺利完成付款，如单个付款渠道的可用资金/额度不足，支付宝可根据您设置的付款顺序（如涉及）或根据推荐设置，组合两种或两种以上渠道完成扣款，具体以产品实际功能为准。<br />
            2.5  如您已自主设置付款顺序，支付宝将按照您设置的付款顺序向您提供服务，经过您授权或同意的场景、银行卡、花呗等资金渠道响应超时或扣款失败的情况除外；如您未设置付款顺序，为了您能获得良好的付款体验，支付宝可根据您的付款习惯或支付宝推荐的设置向您提供服务；您后续可随时在支付宝客户端中自主设置付款顺序。<br />
            2.6  您理解并同意，支付宝只是被授权指令的执行方或信息传递方，除非支付宝没有依照该特定第三方的指令进行操作或信息传递，或操作、传递指令错误，否则支付宝对本服务产生的损失免于承担责任。该等损失与责任应由您与该特定第三方协商解决。</strong><br />
        2.7 您理解并同意，基于风险的防范与控制，支付宝或其他付款渠道服务主体会对您选择的不同付款渠道的付款额度做出不同的控制，日付款授权额度及日授权次数均以具体公告为准。
        若超过该限额的话，将会扣款失败，无法完成付款。<br />
        <strong>2.8  支付宝重视保护您的隐私，按照 《支付宝隐私权政策》 严格保护并处理您的隐私信息。在使用本服务的过程中，您有可能需要同时使用第三方提供的其他服务。例如，在芝麻信用免押或免预付服务中，您需要同时使用芝麻信用管理有限公司提供的服务。在此情况下，相关服务方将依据其与您的授权协议约定，在必要范围内向支付宝收集您的信息。</strong><br />
        2.9 除法律另有规定或本协议另有约定外，支付宝在以公告或其他提示方式提前通知的情况下，可以单方面终止向您提供本服务，并免于承担赔偿责任。支付宝在终止提供本服务后，若发现您之前存在违法或违反本协议目的的使用行为，给支付宝造成损失的，则支付宝仍可据此要求您承担相应赔偿责任。您亦可以根据支付宝客户端的付款设置指引或通过支付宝客服热线申请提前终止本服务协议。<br />
        2.10 支付宝及其他付款渠道服务主体将通过支付宝客户端内推送信息、账单或手机短信等形式为您展示扣款所涉的交易信息。<br />
        3． 其他<br />
        3.1 为了进一步改善用户体验，支付宝将会持续更新服务，为用户提供版本升级、功能升级、规则升级等服务和内容的更新，本协议也可能会随之更新。支付宝会以公告或客户端通知等方式（统称“通知”）就本协议的更新向您进行提示，更新内容将在前述通知指定日期开始生效。若您不同意更新后的条款内容，您有权停止使用相关服务；双方协商一致的，也可另行变更相关服务和对应条款内容。<br />
        3.2 双方在履行本协议的过程中，如发生争议，应协商解决。协商不成的，任何一方均可向 <strong>被告所在地有管辖权的人</strong>提起诉讼。<br />
        3.3 本协议的成立、生效、履行和解释，均适用中华人民共和国法律；法律无明文规定的，应适用支付宝公司制定的业务规则和通行的商业惯例。本协议未约定部分依照《 支付宝服务协议》及相关附属规则予以履行；本协议与《
        支付宝服务协议》及相关附属规则相冲突的，以本协议为准。<br />
    </div>
    `,
}